import React from 'react'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'
import Izizi from '../assets/exhibition/Frame 86.png'
import Izibackground from '../assets/exhibition/Rectangle.png'
import { Link } from 'react-router-dom'
import Arrowright from '../assets/exhibition/Icon.png'
import upcomingexhibitions from '../data/upcomingexhibitions.json'
import allExhibitions from '../data/allExhibitions.json'


const Exhibition = () => {
    // const [exhibition, setExhibition] = useState(upcomingexhibitions);


    // const currentDate = new Date().getFullYear()


    // const upcomingevent = upcomingexhibitions.map(item => {
    //     item.date.getFullyear()
    //     return item.date < currentDate
    // })



    const myStyle = {
        backgroundImage: ` url(${Izibackground})`,
    };
    return (
        <div>
            <MenuBar />
            <div className='text-center mt-10'>
                <div>
                    <h1 className='text-[#F88603] text-6xl  xl:text-[75px] lg:text-[75px] lg:font-extrabold font-bold xl:eading-[60px] xl:tracking-[12px] tracking-[3px]'>Exhibitions</h1>
                </div>
                <div className='lg:mx-[270px] text-[19px] lg:text-[12px]  text-center mx-8 text-[#818285] py-10'>
                    <p className=' md:mx-32'>Visit us for any of our special themed exhibitions. See below for current and upcoming events
                    </p>
                </div>
                <hr className='mx-4 xl:block xl:mx-36 ' />
                {/* <div className='boder-4'></div> */}

            </div>
            <div className='xl:mt-20 md:ml-40 lg:ml-36 pt-10 xl:pt-0 px-4'>
                <h1 className='w-20 xl:w-full font-bold tracking-widest text-[30px]'>Latest exhibition</h1>
            </div>

            <div className=' lg:flex items-center justify-center my-10 lg:mx-20  xl:mx-32 p-4'>

                <div className='md:flex items-center justify-center shadow-xl rounded md:mx-[160px] lg:mx-0 '>
                    <img className='lg:min-w-[490px] md:min-w-[395px]  lg:h-[455px] ' src={Izizi} alt='izizi' />
                </div>

                <div className='shadow-xl rounded items-center justify-center md:mx-[160px] lg:mx-0 bg-[#FEFBF8] '>
                    <div className='py-8' style={myStyle}>

                    </div>
                    <div className='py-7 xl:mx-20 mx-2 '>
                        <p className='py-4'>Friday, 5:00pm - 6:00pm</p>
                        <h1 className='pb-5 text-8xl tracking-widest font-extrabold'>Izizi</h1>
                        <p className='lg:w-[450px] pb-8 px-2 text-[#818285]'>This is the first outing of the new and upgraded Isi Nri Museum. Dont miss this opportunity to experience the exceptional artistry while connecting with fellow art and history enthusiasts of alaigbo.</p>
                        <Link to={`/exhibition/1`}   className='flex pt-4 px-4'>Learn more <img src={Arrowright} alt='icon' /></Link>
                    </div>
                </div>
            </div>

            {/* {upcoming exhibitions} */}
            <div className='bg-[#20413A] py-8'>
                {/* {text} */}
                <div className='flex justify-between mx-4 xl:mx-[11%] md:mx-44 lg:mx-4 mt-20'>
                    <div>
                        <h1 className='text-[#F88603] font-semibold tracking-wide text-4xl'>other upcoming exhibitions</h1>
                    </div>
                    <div className='hidden lg:block'>
                        <Link to={'/allexhibition'} className='border border-[#F6F8F8] text-[#F6F8F8] px-14 py-4'>View all</Link>
                    </div>
                </div>

                {/* {fetch upcoming exhibition} */}
                <div className='lg:flex items-center justify-center gap-4 mt-12 mx-4 space-y-4  lg:space-y-0 md:mx-44 lg:mx-4  ' >
                    {upcomingexhibitions.map((va) => (
                        <div className=' bg-[#FEFBF8] p-2 rounded-md lg:max-w-[400px] h-[560px] ' key={va.id}>
                            <Link to={`/exhibition/${va.id}`}>
                                <div className='flex justify-center'>
                                    <img className='lg:w-[400px] h-[250px] w-full' src={va.image} alt={va.title} />
                                </div>
                                <div className=''>
                                    <p className='text-[#818285] py-4 text-[13px]'>
                                        {va.date}
                                    </p>
                                    <h1 className='text-[#1A1C21] text-2xl font-bold tracking-widest pb-4'>
                                        {va.title}
                                    </h1>
                                    <p className='text-[15px] '>
                                        {va.description.substring(0, 200)}...
                                    </p>
                                </div>

                                <Link to={`/exhibition/${va.id}`} className='flex  pt-4'>Learn more <img src={Arrowright} alt='icon' />
                                </Link>
                            </Link>

                        </div>
                    ))}

                    <ul className='lg:hidden mx-2 text-[20px] items-center justify-center   '  >
                        <li className='border-2 border-[#F6F8F8] mt-16 text-center py-3 px-6  rounded-sm text-white hover:bg-white hover:text-[#1A1C21] '><Link to={'/allexhibition'}>View all</Link></li>
                    </ul>
                </div>
            </div>



            {/* {fetch previous exhibition} */}
            <div className='flex justify-between mx-4 xl:mx-[11%] md:mx-44 lg:mx-4 mt-20 pb-20'>
                <div>
                    <h1 className='lg:text-[#1A1C21] text-[#F88603] font-semibold tracking-wide text-4xl'>previous exhibitions</h1>
                </div>
                <div className='hidden lg:block'>
                    <Link to={'/allexhibition'} className='border border-[#F6F8F8] bg-[#2A564C] text-[#fdfdfd] px-14 py-4'>View all</Link>
                </div>
            </div>


            <div className='lg:grid grid-cols-3 items-center justify-center gap-4 mt-12 mx-4 space-y-4  lg:space-y-0 md:mx-44 lg:mx-4 xl:mx-36  mb-14'>
                {allExhibitions.map((value) => (
                    <div className=' bg-[#FDFDFD] p-2 rounded-md lg:max-w-[400px] shadow-lg h-[540px] '>
                        <Link to={`/allexhibition/${value.id}`}>
                            <div className='flex justify-center'>
                                <img className='lg:w-[400px] h-[250px] w-full' src={value.image} alt={value.title} />
                            </div>
                            <div className='text-[#1A1C21]'>
                                <p className='text-[#818285] py-4 text-[13px]'>
                                    {value.date}
                                </p>
                                <h1 className=' text-2xl font-bold tracking-widest pb-4'>
                                    {value.title}
                                </h1>
                                <p className='text-[15px] '>
                                    {value.description.substring(0, 200)}...
                                </p>
                            </div>

                            <Link to={`/allexhibition/${value.id}`} className='flex pt-4 '>Learn more <img src={Arrowright} alt='icon' />
                            </Link>
                        </Link>
                    </div>



                ))}
                <ul className='lg:hidden mx-2 text-[20px] items-center justify-center   '  >
                    <li className='bg-[#20413A] mt-16 text-center py-3 px-6  rounded-sm text-white hover:bg-white hover:text-[#1A1C21] '><Link to={'/allexhibition'}>View all</Link></li>
                </ul>
            </div>

            <Footer />
        </div>
    )
}

export default Exhibition