import React from 'react'
//import Icon2 from '../assets/bookticket/icon2.png'

import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import Accessimage from '../assets/bookticket/accesstoall.png'
import Guidedtours from '../assets/bookticket/guidedtour.png'
import Pamphlet from '../assets/bookticket/pamphlet.png'
import Discounticon from '../assets/bookticket/discount.png'
import Priorityicon from '../assets/bookticket/priorityentry.png'
import Visacard from '../assets/bookticket/logos_visa.png'
import Mastercard from '../assets/bookticket/logos_mastercard.png'
import PaypalLogo from '../assets/bookticket/paypal.png'

const OneTimePassChecOut = () => {
    return (
        <div>
     
            <div>
                <div className='bg-[#171D1A] pb-60 '>
                    <div className=' ml-[19%] pb-4 pt-20' >
                        <p className=' text-white text-[19px]'> <Link className='' to={'/'}>Home </Link>  |  <Link className='' to={'/bookticket'}>Ticket </Link> | <span className='text-[#F88603]' >Checkout</span></p>
                    </div>

                </div>


                <div className='lg:flex  items-center justify-center mt-[-16%] pb-20'>

                    <div className='bg-[#fff] items-center text-center justify-center border border-[#171d1a] pb-1 px-20' >
                        <div className='mt-9'>
                            <p>PAYMENT METHODS</p>
                        </div>
                        <div className='flex items-center justify-center shadow-lg rounded-md py-8 my-8 mx-[15%]  xl:mx-4 md:mx-[39%] lg:mx-[0%]'>
                            <img src={Visacard} alt='visa logo img' />
                        </div>
                        <div className='flex my-8 items-center justify-center  shadow-lg rounded-md py-8 mx-[15%] md:mx-[39%] xl:mx-4 lg:mx-[0%] '>
                            <img src={Mastercard} alt='visa logo img' />
                        </div>
                        <div className='flex my-8 items-center justify-center'>
                            <img src={PaypalLogo} alt='visa logo img' />
                        </div>

                    </div>


                    <div className='bg-[#171D1A] text-[#fff] py-24 px-8 lg:px-20 ' >


                        <form>
                            <label className='text-[12px]'>Card Number</label><br />
                            <input className=' border-b border-white  bg-[#171d1a] w-full' type='text' /> <br /><br />

                            <label className='text-[12px]'>Card Holder Name</label><br />
                            <input className='border-b border-white bg-[#171d1a] w-full ' type='text' />

                            <div className='flex space-x-10  justify-between mt-4 '>
                                <div>
                                    <label className='text-[12px]'>Expiration Date</label><br />
                                    <input className='border-b w-full border-white bg-[#171d1a] ' type='number' />
                                </div>
                                <div>
                                    <label className='text-[12px]'>CVV</label><br />
                                    <input className='border-b w-full border-white bg-[#171d1a] ' type='number' />
                                </div>
                            </div>
                            <div className='mt-8  bg-[#2A564C]' >
                                <div className='flex justify-between mb-2 '>
                                    {/* <label className='text-[12px] p-2 mx-1'>Amount</label> */}
                                    <input className='w-full placeholder:text-[12px] p-2 placeholder:mr-4 bg-[#2A564C] placeholder:text-[#fff] ' type='text' placeholder='Amount' />
                                </div>
                                {/* <div className='flex justify-between'>
                                    <label className='text-[12px]   mx-2'>Total Amount</label>
                                    <input className='w-full placeholder:text-[12px] placeholder:mr-4 bg-[#2A564C] placeholder:text-[#fff] placeholder:float-right ml-8' type='text' placeholder='N5000 NGN' />
                                </div> */}
                            </div>
                            <div className='items-center justify-center flex mt-8'>
                                <button className='bg-[#2A564C] w-full px-24 py-4 rounded'>Confirm Payment</button>
                            </div>
                        </form>

                    </div>
                </div>
                <div className='bg-[#171D1A] p-8'></div>

                <div className='lg:flex items-center justify-center lg:space-x-8 py-28 lg:mx-20 space-y-2 md:mx-16 md:space-y-4 lg:space-y-0 mx-4'>

                    <div className='text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16  rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3]  md:py-[6%] mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img className='' src={Accessimage} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>ACCESS TO ALL EXHIBITS</h1>
                        <p className='text-[15px]'>Explore every corner of our museum, from permanent collections to special exhibitions, and experience a diverse range of art, history, and culture. </p>
                    </div>

                    <div className='text-center items-center lg:min-w-[300px] h-[400px]    lg:w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>

                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Guidedtours} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>GUIDED TOURS</h1>
                        <p className='text-[15px]'>Join our expert guides on informative tours that offer deeper insights into our most popular exhibits, enhancing your visit with fascinating stories and details. </p>
                    </div>

                    <div className='text-center items-center lg:min-w-[300px]  h-[400px]  lg:max-w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>

                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Pamphlet} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>FREE TOUR PAMPHLETS</h1>
                        <p className='text-[15px]'>Receive a complimentary pamphlet that provides valuable information about the exhibits, helping you navigate and appreciate the museum’s highlights at your own pace.</p>
                    </div>
                    <div className='lg:hidden text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16  rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img className='' src={Discounticon} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>3% DISCOUNT IN MERCH</h1>
                        <p className='text-[15px]'>Enjoy a 3% discount on all purchases at our museum shop, where you can find unique souvenirs, books, and gifts to commemorate your visit.</p>
                    </div>

                    <div className='lg:hidden text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16  rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img className='' src={Priorityicon} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>PRIORITY ENTRY</h1>
                        <p className='text-[15px]'>Skip the lines with priority entry, allowing you to start your museum adventure without the wait.</p>
                    </div>
                </div>


                {/* {second} */}
                <div className='hidden lg:flex items-center justify-center lg:space-x-16 pb-20 lg:mx-28'>
                    <div className='text-center items-center lg:min-w-[300px] h-[40
                0px] lg:w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Discounticon} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>3% DISCOUNT IN MERCH</h1>
                        <p className='text-[15px]'>Enjoy a 3% discount on all purchases at our museum shop, where you can find unique souvenirs, books, and gifts to commemorate your visit.</p>
                    </div>

                    <div className='text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Priorityicon} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>PRIORITY ENTRY</h1>
                        <p className='text-[15px]'>Skip the lines with priority entry, allowing you to start your museum adventure without the wait. </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OneTimePassChecOut