import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import "../DonatePage.css";
import axios from "axios"

function Donate({ visibility, onClose }) {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    country: "",
    stateProvince: "",
    city: "",
    inMemoryOf: "",
    isAnonymous: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.example.com/donations', formData);
      console.log('Donation submitted successfully:', response.data);
      // Handle success (e.g., show a success message, close the modal)
      onClose();
    } catch (error) {
      console.error('Error submitting donation:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };
  return (
    <div className=" modal" style={{ display: visibility ? "block" : "none" }}>
      <div className="donate w-[80%] lg:w-[50%] rounded-md">
       
        <button className="float-right" onClick={onClose}>
          <AiFillCloseCircle size={30} />
        </button>
        <div className="text-[#1A1C21] lg:mx-16">
          <h1 className="text-center lg:text-4xl text-2xl lg:mx-20 pt-12 tracking-widest  font-semibold">
            Your Support Makes a Different
          </h1>
          <h2 className="pt-16 text-2xl font-semibold">Donor Information: </h2>
          <p className="text-[#2F3035] pt-2">
            Kindly fill in your details. We will reach out to you immediately
          </p>

          <form className="mt-10 pb-20" onSubmit={handleSubmit}>
          <input
            className="bg-[#F6F8F8] border-b border-[#6C6E71] placeholder:text-[#1A1C21] text-[18px] pb-4 w-full"
            type="text"
            name="fullName"
            placeholder="Full Name"
            required
            value={formData.fullName}
            onChange={handleInputChange}
          />
            <br />
            <br />
            <input
              className="bg-[#F6F8F8] border-b border-[#6C6E71] placeholder:text-[#1A1C21] text-[18px] pb-4 w-full"
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email Address"
            />
            <br />
            <br />
            <input
              className="bg-[#F6F8F8] border-b border-[#6C6E71] placeholder:text-[#1A1C21] text-[18px] pb-4 w-full"
              type="number"
              name="phoneNumber"
              required
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Phone Number"
            />
            <br /> <br />
            <input
              className="bg-[#F6F8F8] border-b border-[#6C6E71] placeholder:text-[#1A1C21] text-[18px] pb-4 w-full"
              type="text"
              name="country"
              required
              value={formData.country}
              onChange={handleInputChange}
              placeholder="Country"
            />
            <br /> <br />
            <input
              className="bg-[#F6F8F8] border-b border-[#6C6E71] placeholder:text-[#1A1C21] text-[18px] pb-4 w-full"
              type="text"
              name="stateProvince"
              value={formData.stateProvince}
              onChange={handleInputChange}
              placeholder="State /Province"
            />
            <br /> <br />
            <input
              className="bg-[#F6F8F8] border-b border-[#6C6E71] placeholder:text-[#1A1C21] text-[18px] pb-4 w-full"
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
            />
            <br />
            <br />
            <p className="font-semibold pt-2 text-[20px]">
              Additional Information:
            </p>
            <p className="pt-4 text-[18px]">
              Is this donation in memory of someone?
            </p>
            <label className="pr-3 text-[20px]">Yes</label>
            <input 
            type="radio" 
            name="inMemoryOf" 
            value={formData.inMemoryOf}
            onChange={handleInputChange}
            required
            />
            <label className="ml-3 pr-3 text-[20px]">No</label>
            <input 
            type="radio" 
            name="inMemoryOf"
            value={formData.inMemoryOf}
            onChange={handleInputChange}
            required
             />
            <br />
            <label>If yes, please provide their name</label>
            <input
              className="w-[50%] border-b border-[#6C6E71] bg-[#F6F8F8]"
              type="text"
            />
            <br />
            <br />
            <br />
            <p className="p12-4 text-[18px]">
              Do you want to remain Anonymous?
            </p>
            <label className="pr-3 text-[20px]">Yes</label>
            <input 
            type="radio" 
            name="isAnonymous"
            value={formData.isAnonymous}
            onChange={handleInputChange} 
            />
            <label className="ml-3 pr-3 text-[20px]">No</label>
            <input 
            type="radio" 
            name="isAnonymous"
            value={formData.isAnonymous}
            onChange={handleInputChange} 
            />
            <p className="text-[#2F3035] my-8 text-[14px]">
              Thank you for considering a donation. Together, we can ensure that
              our museum remains a vibrant and valuable resource for all.
            </p>
            <div className="flex justify-center mt-20">
              <button type="submit" className="bg-[#2A564C] rounded text-2xl py-4 w-full text-[#FBFCFD] flex justify-center">
                Submit Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Donate;
