import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { CgMenuRight } from "react-icons/cg";
import Logo from "../assets/header/image 26.png";
import { Link } from "react-router-dom";
import Donate from "../pages/Donate";
import LoginAttendantModal from "../pages/LoginAttendantModal";

const MenuBar = () => {
  const [donateVisibility, setDonateVisibilty] = useState(false);
  const [loginVisibility, setLoginVisibilty] = useState(false);

  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className='  w-full h-[120px] xl:h-[140px] flex justify-between items-center xl:px-[140px] bg-[#FDFDFD] text-gray-400 px-6'>
      <Donate
        visibility={donateVisibility}
        onClose={() => setDonateVisibilty(false)}
      />
      <LoginAttendantModal
        visibility={loginVisibility}
        onClose={() => setLoginVisibilty(false)}
      />

      <div>
        <Link to={'/'}>
          <img className='xl:w-[131.774px] w-[90px]' src={Logo} alt='logo' />
        </Link>
      </div>

      {/* menu */}
      <nav className='hidden lg:flex gap-x-[20px]  text-center justify-center p-4 '>
        <Link
          to={'/gallery'}
          className='text-[#898EAD]  hover:text-[#004225] hover:border-b-2 hover:border-[#004225] p-2 '
        >
          Gallery
        </Link>
        <Link
          to={'/about'}
          className='text-[#898EAD] hover:text-[#004225] hover:border-b-2 hover:border-[#004225] p-2'
        >
          About us
        </Link>
        <Link
          to={'/exhibition'}
          className='text-[#898EAD] hover:text-[#004225] hover:border-b-2 hover:border-[#004225] p-2'
        >
          Exhibitions
        </Link>
        <Link
          to={'/giftshop'}
          className='text-[#898EAD] hover:text-[#004225] hover:border-b-2 hover:border-[#004225] p-2'
        >
          Gift shop
        </Link>
        <Link
          to={'/history'}
          className='text-[#898EAD] hover:text-[#004225] hover:border-b-2 hover:border-[#004225] p-2 '
        >
          Learn History
        </Link>
      </nav>

      <div className='hidden lg:flex  border-l-2 items-center gap-[20px] pl-[20px]  text-[#898EAD]'>
        <div className='flex gap-[20px]'>
          <Link onClick={() => setLoginVisibilty(true)} className='flex gap-2'>
            <div>Access</div>
            <FaUser className='w-[24px] h-[20px]  ' />
          </Link>
        </div>
        <div>
          <Link onClick={() => setDonateVisibilty(true)}>Donate</Link>
        </div>

        <div className='hidden md:flex bg-[#2A564C] justify-center items-center px-[32px] py-[9px] rounded-md text-white'>
          <Link to='/bookticket'>Book ticket</Link>
        </div>
      </div>

      {/* hambuger */}
      <div className='lg:hidden z-10 text-3xl text-black'>
        <div>
          {!nav ? (
            <div className='flex gap-4'>
              <Link onClick={() => setLoginVisibilty(true)}>
                <FaUser />
              </Link>
              <CgMenuRight onClick={handleClick} />
            </div>
          ) : (
            <IoMdClose onClick={handleClick} />
          )}
        </div>
      </div>

      {/* mobile / menu */}
      <div
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full max-h-screen flex flex-col pl-5 bg-[#FBEBD6] '
        }
      >
        <ul className='space-y-8 pb-10'>
          <li className='pt-4 mt-3'>
            <Link to={'/'}>
              <img className='xl:w-[131.774px] w-[90px]' src={Logo} alt='logo' />
            </Link>
          </li>
          <li className='pt-2 text-[21px]'>
            <Link onClick={handleClick} to='/gallery'>
              Gallery
            </Link>
          </li>
          <li className=' text-[21px]'>
            <Link onClick={handleClick} to='/about'>
              About us
            </Link>
          </li>
          <li className='text-[21px]'>
            <Link onClick={handleClick} to='/exhibition'>
              Exhibitions
            </Link>
          </li>
          <li className=' text-[21px]'>
            <Link onClick={handleClick} to='/giftshop'>
              Gift shop
            </Link>
          </li>
          <li className=' text-[21px]'>
            <Link onClick={handleClick} to='/history'>
              Our History
            </Link>
          </li>
          <li className=' text-[21px]'>
            <Link onClick={handleClick} to='history'>
              Contact us
            </Link>
          </li>
        </ul>

        {/* <div className="border mt-12 mx-2"></div> */}
        <div className='border-t-2  border-[#BFBFC1]  grid grid-cols-2 py-10 mx-4 '>
          <div>
            <Link onClick={() => setDonateVisibilty(true)} className='text-[19px]'>
              Donate
            </Link>
          </div>
          <ul className='bg-[#2A564C] text-[15px] text-center px-8 py-4'>
            <li>
              <Link to={'/bookticket'}>Book Ticket</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
