import React from 'react'
import { useParams } from 'react-router-dom'
import allExhibitions from '../data/allExhibitions.json'
import upcomingexhibitions from '../data/upcomingexhibitions.json'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'
import Arrowright from '../assets/exhibition/Icon.png'
import { Link } from 'react-router-dom'
import Phone from '../assets/exhibition/phone.png'
import Clock from '../assets/exhibition/clock.png'
import Calender from '../assets/exhibition/calender.png'
import Location from '../assets/exhibition/location.png'



function AllExhibitionDetailPage() {
    const { id } = useParams()
    const ExhibitionDetail = allExhibitions.find(item => {
        return item.id === parseInt(id)
    })
    if (!ExhibitionDetail) {
        return <section>Loading....</section>

    }

    return (
        <div>
            <MenuBar />
            <div>
                <div className='xl:flex items-center justify-between lg:mb-14 lg:mt-20 mt-14 xl:mx-60 md:mx-44 '>
                    <div className='flex gap-10 mx-4 lg:mx-0 '>
                        <p className='bg-slate-200 text-center mt2 mb-8 p-[10px] rounded font-bold text-[15px] text-[#1A1C21]'>{ExhibitionDetail.date.substring(0, 10)}</p>
                        <h1 className='font-bold text-4xl lg:text-[48px] leading-16'>{ExhibitionDetail.title}</h1>
                    </div>
                    <div className='hidden lg:block'>
                        <Link to={'/bookticket'} className='bg-[#2A564C] border border-[#2A564C] rounded text-[#F6F8F8] px-10 py-4'> Ticket </Link>
                    </div>

                    {/* {mobile button} */}
                    <ul className='lg:hidden'>
                        <li className='bg-[#2A564C] mx-4 my-2  py-5 text-center rounded-sm text-white'><Link className='text-[20px]' to={'/bookticket'}>Book ticket</Link></li>
                    </ul>
                </div>
                <div className='flex mt-8 mx-4 items-center  justify-center lg:mx-36 md:mx-48 '>
                    <img className='w-[1100px] h-[400px] border-4 border-[#F5E0CB]' src={ExhibitionDetail.image} alt={ExhibitionDetail.title} />
                </div>
                {/* <div className='hidden lg:block py-40 mt-[-200px]' style={myStyle}>
                </div> */}

                <div className='bg-[#FEF9F4]  mt-[-200px] py-10'>
                    <div className=' my-28 lg:flex gap-4 lg:mx-44 mx-4 md:mx-48 pt-32' >
                        <div className='bg-[#FDFDFD] border p-4  lg:w-[700px] mb-10 lg:mb-0'>
                            <h1 className='text-[#1A1C21] font-bold tracking-widest text-[36px] pt-8 pb-8'>About {ExhibitionDetail.title}</h1>
                            <p className='leading-[35px] text-[15px] text-[#818285]'>{ExhibitionDetail.description}</p>
                            {/* <div className='hidden lg:block py-16 '>
                            <Link to={'/bookticket'} className='bg-[#2A564C] border border-[#2A564C] rounded text-[#F6F8F8] px-14 py-4'>Book ticket</Link>
                        </div> */}


                            {/* {mobile button} */}

                            {/* <ul className='lg:hidden'>
                            <li className='bg-[#2A564C]  my-8  py-5 text-center rounded-sm text-white'><Link className='text-[20px]' to={'/bookticket'}>Book ticket</Link></li>
                        </ul> */}
                        </div>

                        {/* {exhibition info} */}
                        <div className='border lg:px-8 px-4 bg-[#FDFDFD] pt-8 ' >
                            <h1 className='text-[#1A1C21] font-bold tracking-widest text-[36px]'>Exhibition Info</h1>
                            <p className='text-[#818285] eading-[30px] text-[15px]'>{ExhibitionDetail.description.substring(0, 70)}</p>
                            <div className='flex gap-4 text-[#818285] pt-8 '>
                                <img className='' src={Phone} alt='phone' />
                                <p className='text-[18px]'>0812 345 6789</p>
                            </div>

                            <div className='flex gap-4 text-[#818285] pt-8 '>
                                <img className='' src={Calender} alt='calender' />
                                <p className='text-[18px]'>{ExhibitionDetail.date}</p>
                            </div>

                            <div className='flex gap-4 text-[#818285] pt-8 '>
                                <img className='' src={Clock} alt='clock' />
                                <p className='text-[18px]'>02:00 PM</p>
                            </div>

                            <div className='flex gap-4 text-[#818285] pt-8 '>
                                <img className='' src={Location} alt='phone' />
                                <p className='text-[18px]'>Isi-Nri Musuem Enugwu Ukwu</p>
                            </div>

                            {/* <div className='hidden   float-none lg:flex justify-center pt-14'>
                            <Link to={'/bookticket'} className='bg-[#2A564C] border border-[#2A564C] rounded text-[#F6F8F8] px-32 py-4'>Book ticket</Link>
                        </div> */}

                            {/* {mobile button} */}
                            {/* <ul className='lg:hidden'>
                            <li className='bg-[#2A564C]  my-8  py-5 text-center rounded-sm text-white'><Link className='text-[20px]' to={'/bookticket'}>Book ticket</Link></li>
                        </ul> */}
                        </div>
                    </div>
                </div>

                {/* {photo speakes} */}
                <div className='bg-[#20413A] '>
                    <div className='pt-20 mx-4  pb-10 xl:mx-40 lg:mx-20'>
                        <h1 className='text-[#F88603] text-3xl font-semibold tracking-wider'>Photo speaks</h1>
                    </div>
                    <div className='md:flex items-center justify-center gap-4 pb-16 mx-4 lg:mx-20 xl:mx-40 space-y-4 md:space-y-0' >
                        <div className='space-y-4'>
                            <div className='flex items-center justify-center '>
                                <img className='flex items-center justify-center w-[500px] md:w-[813px] md:h-[350px]' src={ExhibitionDetail.image2[0]} alt={ExhibitionDetail.title} />
                            </div>
                            <div className='md:flex gap-4 space-y-4 md:space-y-0'>
                                <div className='flex items-center justify-center '>
                                    <img className='w-[500px] md:w-[400px] md:h-[300px]' src={ExhibitionDetail.image2[1]} alt={ExhibitionDetail.title} />
                                </div>
                                <div className='flex items-center justify-center '>
                                    <img className='w-[500px] md:w-[400px] md:h-[300px]' src={ExhibitionDetail.image2[2]} alt={ExhibitionDetail.title} />
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-center '>
                            <img className='w-[500px] md:w-[450px] md:h-[660px]' src={ExhibitionDetail.image2[3]} alt={ExhibitionDetail.title} />
                        </div>
                    </div>

                </div>

                <div className='py-2 '>
                    {/* {other upcoming exhibitons} */}
                    <div className='flex justify-between md:mx-44 lg:mx-4 mx-4 xl:mx-[11%] mt-20'>
                        <div className=''>
                            <h1 className='lg:text-[#1A1C21] text-[#F88603] font-semibold tracking-wide text-4xl'>other upcoming exhibitions</h1>
                        </div>
                        <div className='hidden lg:block'>
                            <Link to={'/allexhibition'} className='border border-[#F6F8F8] text-[#F6F8F8] bg-[#2A564C] px-14 py-4'>View all</Link>
                        </div>
                    </div>


                    {/* {fetch from db} */}
                    <div className='lg:flex items-center justify-center gap-8 mt-12 mx-4 space-y-6  mb-20 lg:space-y-0 md:mx-44 lg:mx-1'>
                        {upcomingexhibitions.map((va) => (
                            <div className=' bg-[#FEFBF8] p-2 rounded-md lg:max-w-[400px] h-[540px]' key={va.id}>

                                <div className='flex justify-center'>
                                    <img className='lg:w-[400px] h-[250px] w-full' src={va.image} alt={va.title} />
                                </div>
                                <div className=' text-[#818285]'>
                                    <p className=' py-4 text-[13px]'>
                                        {va.date}
                                    </p>
                                    <h1 className='text-[#1A1C21] text-2xl font-bold tracking-widest pb-4'>
                                        {va.title}
                                    </h1>
                                    <p className='text-[15px]  '>
                                        {va.description.substring(0, 200)}...
                                    </p>
                                </div>

                                <Link to={`/exhibition/${va.id}`} className='flex pt-4 font-semibold'>Learn more <img src={Arrowright} alt='icon' />

                                </Link>

                            </div>
                        ))}

                        <ul className='lg:hidden mx-2 text-[20px] items-center justify-center   '  >
                            <li className='border-2 border-[#F6F8F8] mt-16 text-center py-3 px-6  rounded-sm text-white hover:bg-white hover:text-[#1A1C21] '><Link to={'/allexhibition'}>View all</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AllExhibitionDetailPage