import React, { useState } from "react";
import Nav from './Nav'
import Image1 from '../assets/admin/image 12.png'
import MiniCard from '../assets/admin/mini card.png'
import AddItem from '../assets/admin/add.png'
import Product from '../pages/giftshop/Product.json'
import { Link } from 'react-router-dom'
import SoldProduction from '../pages/giftshop/SoldProduct.json'


export default function Shop() {
  const [active, setActive] = useState("Instock");
  const [items, setItems] = useState(Product)
  const [soldItem, setSoldItems] = useState(SoldProduction)


  const filterItems = (catItem) => {
    const updateItems = Product.filter((curItem) => {
      return curItem.category === catItem
    });
    setItems(updateItems)
  }

  const filterSoldItems = (catItem) => {
    const updateSoldItems = SoldProduction.filter((curItem) => {
      return curItem.category === catItem
    });
    setSoldItems(updateSoldItems)
  }
  return (
    <div>
      <div className='lg:flex'>
        <div>
          <Nav />
        </div>

        <div className='w-full'>

          <div className=' mx-14 my-4 flex text-justify justify-between'>
            <div><p className='font-medium'>Home</p></div>
            <div><p className='font-medium'>Hello Admin</p></div>
          </div>

          <div className='flex justify-between bg-[#FBEBD6] mt-8'>
            <div className='p-8'>
              <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>The Giftshop</h1>
              <p className='font-medium text-[12px] pt-2 text-[#818285]'>Here's all you need to know about the museum at a glance</p>
            </div>
            <div className='mt-8 md:mt-0 mx-2'>
              <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
            </div>
          </div>

          <div className='md:flex justify-center gap-4 mt-10 space-y-4 md:space-y-0 mx-2 md:mx-0'>
            <div className='flex bg-[#F2F2F3] p-4 gap-2 rounded-md'>
              <div>
                <img src={MiniCard} alt='all products' />
              </div>
              <div>
                <p className='text-[13px]'>All Products</p>
                <p className='font-medium text-[18px]'>1234</p>
              </div>
            </div>

            <div className='flex bg-[#F2F2F3] gap-2 p-4 rounded-md'>
              <div>
                <img src={MiniCard} alt='Available products' />
              </div>
              <div>
                <p className='text-[13px]'> Currently Available</p>
                <p className='font-medium text-[18px]'>1234</p>
              </div>
            </div>

            <div className='flex bg-[#F2F2F3] gap-2 p-4 rounded-md'>
              <div>
                <img src={MiniCard} alt='revenue generated' />
              </div>
              <div>
                <p className='text-[13px]'>Revenue Generated</p>
                <p className='font-medium text-[18px]'>1234</p>
              </div>
            </div>


            <Link to={'/AdminAddProducttoGiftShop'} className='flex bg-[#FDDDB8] gap-2 p-4 rounded-md'>
              <div>
                <img src={AddItem} alt='add products' />
              </div>
              <div className='text-[13px] mt-2'>Add New</div>
            </Link>
          </div>



          {/* click to display stocks */}
          <div className="flex justify-center md:justify-start  bg-[#FDFDFD] mx-2  mt-10 md:mx-8 rounded-md">

            <div>
              <div
                className={`cursor-pointer ${active === "Instock" ? "text-[#2A564C] bg-[#F6F8F8] py-3 border-b-4 px-12 md:px-16 border-[#2A564C]" : "text-[#818285] py-3 px-12 md:px-16"
                  }`}
                onClick={() => setActive("Instock")}
              >

                <p className=" ">
                  In Stock
                </p>

              </div>
            </div>


            <div>
              <div
                className={`cursor-pointer ${active === "soldproduct" ? "text-[#2A564C] bg-[#F6F8F8] p-3 border-b-4 px-10 md:px-16 border-[#2A564C]" : "text-[#58595D] py-3 px-12 md:px-16"
                  }`}
                onClick={() => setActive("soldproduct")}
              >
                <p className=" ">
                  Sold Products
                </p>

              </div>
            </div>
          </div>


          {/* instock and sold products dashboard */}
          <div className="border mx-2 md:mx-8 my-8 rounded-md">

            {/* instock starts here */}
            {active === "Instock" && (
              <div
                className=" "
                title="Instock"
              >

                <div className=" md:flex justify-between mt-4 mx-2  md:mx-10 md:mt-8 mb-12 space-y-2 md:space-y-0">
                  <div>
                    <h1>Products</h1>
                  </div>
                  <div className=" ">
                    <ul className='flex text-[12px] border-2  text-[#818285]' >

                      <li className='flex cursor-pointer py-2 md:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => setItems(Product)}>
                        All
                      </li>
                      <li className='flex cursor-pointer py-2 md:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => filterItems("jewelery")}>
                        Trending
                      </li>
                      <li className='flex cursor-pointer py-2 pmd:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => filterItems("women's clothing")}>
                        Recent
                      </li>
                      <li className='flex cursor-pointer py-2 md:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => filterItems("men's clothing")}>
                        Oldest
                      </li>
                    </ul>
                  </div>
                </div>
                {/* {text} */}

                <div className="flex justify-center">
                  <table className="w-full">


                    <div className=" flex justify-center p-4 md:w-full md:space-x-20 bg-[#F2F2F3] text-[#2A564C] space-x-4 text-[11px]  md:text-[16px]">
                      <div className="">PRODUCTS</div>
                      <div>CATEGORY</div>
                      <div>TOTAL SALES</div>
                      <div>PRICE</div>
                      <div>IN STOCK</div>
                    </div>

                    {items.map((val) => (
                      <Link to={`/AdminShopProductDetail/${val.id}`}>
                        <tr className=" flex justify-center border space-x-8 md:space-x-28 p-4" key={val.id}>
                          <td className="flex">

                            <img className='md:w-[100px] md:max-w-[100px] w-[50px] max-w-[50px] h-[50px]  md:h-[100px] md:p-4' src={val.image} alt={val.title} />

                            <p className="hidden lg:block mt-10">{val.title.substring(0, 8)}</p>
                          </td>

                          <td className='pt-4 pb-2'>
                            <h5 className='text-left text-[#1A1C21] text-[14px]'>{val.category.substring(0, 8)}</h5>

                          </td>

                          <td>
                            0
                          </td>



                          <td className='text-[#818285] text-left text-[13px]'>
                            <p>{'$' + val.price}</p>
                          </td>

                          <td>
                            <p>{val.amount}</p>
                          </td>
                        </tr>
                      </Link>
                    ))}

                  </table>

                </div>

                {/* {images} */}

              </div>
            )}



            {/* sold product dashboard */}

            {active === "soldproduct" && (
              <div
                className=" "
                title="Sold Products"
              >

                <div className=" md:flex justify-between mt-4 mx-2  md:mx-10 md:mt-8 mb-12 space-y-2 md:space-y-0">
                  <div>
                    <h1>Products</h1>
                  </div>
                  <div className=" ">
                    <ul className='flex text-[12px] border-2  text-[#818285]' >

                      <li className='flex cursor-pointer py-2 md:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => setSoldItems(SoldProduction)}>
                        All
                      </li>
                      <li className='flex cursor-pointer py-2 md:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => filterSoldItems("jewelery")}>
                        Trending
                      </li>
                      <li className='flex cursor-pointer py-2 pmd:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => filterSoldItems("women's clothing")}>
                        Recent
                      </li>
                      <li className='flex cursor-pointer py-2 md:px-6 px-4 hover:bg-[#FDDDB8]  hover:text-black md:hover:px-6 hover:px-4' onClick={() => filterSoldItems("men's clothing")}>
                        Oldest
                      </li>
                    </ul>
                  </div>
                </div>
                {/* {text} */}

                <div className="flex justify-center">
                  <table className="md:w-full">


                    <div className=" flex justify-center p-4 md:w-full md:space-x-20 bg-[#F2F2F3] text-[#2A564C] space-x-4 text-[11px]  md:text-[16px]">
                      <div className="">PRODUCTS</div>
                      <div>CATEGORY</div>
                      <div>QUANTITY</div>
                      <div>AMOUNT</div>
                      <div>STATUS</div>
                    </div>

                    {soldItem.map((val) => (

                      <tr className=" flex justify-center border space-x-8 md:space-x-28 p-4" key={val.id}>
                        <td className="flex">
                          <Link to={`/giftshop/${val.id}`}>
                            <img className='md:w-[100px] md:max-w-[100px] w-[50px] max-w-[50px] h-[50px]  md:h-[100px] md:p-4' src={val.image} alt={val.title} />
                          </Link>
                          <p className="hidden lg:block mt-10">{val.title.substring(0, 8)}</p>
                        </td>

                        <td className='pt-4 pb-2'>
                          <h5 className='text-left text-[#1A1C21] text-[14px]'>{val.category.substring(0, 8)}</h5>

                        </td>

                        <td>
                          <p>{val.qty}</p>
                        </td>



                        <td className='text-[#818285] text-left text-[13px]'>
                          <p>{'$' + val.price}</p>
                        </td>

                        <td>
                          <p>{val.amount}</p>
                        </td>
                      </tr>
                    ))}

                  </table>

                </div>

                {/* {images} */}

              </div>
            )}

          </div>
        </div>
      </div>

    </div>
  )
}
