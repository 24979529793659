import React from 'react'
import Nav from './Nav'
import Image1 from '../assets/admin/image1.jpeg'
import { Link } from 'react-router-dom'
import HistoryInfo from '../data/history.json'
import ExhibitionHistory from '../data/allExhibitions.json'
import LatestArtWork from '../data/gallery.json'


function Home() {
  const sideImage = HistoryInfo.find(item => {
    return item.id === 1
  })

  const LatestArt1 = LatestArtWork.find(item => {
    return item.id === 3
  })
  const LatestArt2 = LatestArtWork.find(item => {
    return item.id === 6
  })

  return (
    <div>
      <div className='lg:flex'>
        <div className=''>
          <Nav />
        </div>
        <div className='w-full '>
          <div className=' hidden  mx-14 my-4 lg:flex text-justify justify-between'>
            <div><p className=' font-medium'>Home</p></div>
            <div><p className='font-medium'>Hello Admin</p></div>
          </div>

          <div className='flex justify-between bg-[#FBEBD6] mt-8'>
            <div className='p-8'>
              <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>Hello Admin</h1>
              <p className='font-medium text-[12px] pt-2 text-[#818285]'>Here's all you need to know about the museum at a glance</p>
            </div>
            <div className='mt-8 md:mt-0 mx-2'>
              <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
            </div>
          </div>


          <div className='lg:flex justify-center gap-4 my-8 space-y-4 lg:space-y-0'>
            <div>
              <div className='lg:grid lg:grid-cols-2 gap-4 space-y-4 lg:space-y-0 '>

                <div className='bg-[#F2F2F3] p-4'>
                  <div className='flex justify-between'>
                    <div><p>Giftshop</p></div>
                    <div>
                      <p>Revenue</p>
                      <p className='font-extrabold tracking-widest text-[17px]'>N30,000</p>
                    </div>
                  </div>

                  <ul className=" items-center justify-center md:flex md:gap-4 mt-2 ">
                    <li className="px-16 py-2 bg-[#F88603] text-center   rounded-sm text-white ">
                      <Link to={'/giftshop'}>Go to giftsshop</Link>
                    </li>
                  </ul>
                </div>


                <div className='bg-[#F2F2F3] p-4'>
                  <div className='flex justify-between'>
                    <div><p>Ticket</p></div>
                    <div>
                      <p>Revenue</p>
                      <p className='font-extrabold tracking-widest text-[17px]'>N50,000</p>
                    </div>
                  </div>

                  <ul className=" items-center justify-center md:flex md:gap-4 mt-2 ">
                    <li className="px-16 py-2 bg-[#F88603] text-center   rounded-sm text-white ">
                      <Link to={'/exhibition'}> Go to exhibition</Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* transaction history */}

              <div className='lg:flex lg:gap-4  mt-10'>
                <div className='border p-4'>
                  <h1>Giftshop Transaction History</h1>


                  <div className='bg-[#FBEBD6] p-4 my-4'>
                <h1>No Transaction yet</h1>
                  </div>

                  <ul className=" items-center justify-center md:flex md:gap-4  ">
                    <li className="px-24 py-2 bg-[#F88603] text-center   rounded-sm text-white ">
                      <Link to={'/exhibition'}> View all</Link>
                    </li>
                  </ul>
                </div>

                <div className='border p-4'>
                  <h1>Exhibition History</h1>

                  <div>
                    {ExhibitionHistory.map((va) => (
                      <div className='flex bg-[#FBEBD6] p-2 mt-4 mb-4 gap-4' key={va.id}>

                        <div className=''>
                          <img className='w-[70px] h-[50px] ' src={va.image} alt={va.title} />
                        </div>
                        <div className=''>
                          <p className='text-[#818285]  text-[13px]'>
                            {va.date.substring(0, 20)}
                          </p>
                          <h1 className='text-[#1A1C21] text-[13px] '>
                            {va.title.substring(0, 10)}
                          </h1>
                          <p className='text-[13px] '>
                            {va.description.substring(0, 20)}...
                          </p>
                        </div>


                      </div>
                    ))}
                  </div>

                  <ul className=" items-center justify-center md:flex md:gap-4  mt-2">
                    <li className="px-24 py-2 bg-[#F88603] text-center   rounded-sm text-white ">
                      <Link to={'/exhibition'}> View all</Link>
                    </li>
                  </ul>
                </div>
              </div>

            </div>


            <div>
              <div className='bg-[#F2F2F3] p-4'>
                <div className='lg:text-center'>
                  <p>Documentary</p>

                  <div className='flex space-x-2 my-4'>
                    <div className=''>
                      <img className='w-[70px] h-[50px]' src={sideImage.image} alt={sideImage.title} />
                    </div>
                    <div className=' text-start'>
                      <p className='text-[13px]'>{sideImage.title.substring(0, 27)}</p>
                      <p className='text-[13px] '>{sideImage.description.substring(0, 12)}<br /></p>
                    </div>
                  </div>
                </div>

                <ul className=" items-center justify-center md:flex md:gap-4  ">
                  <li className="px-24 py-2 bg-[#F88603] text-center   rounded-sm text-white ">
                    <Link to={'/history'}> View all</Link>
                  </li>
                </ul>

              </div>


              {/*  latest artworks*/}

              <div className='border p-4 mt-10'>
                <div>
                  <h1>Latest Artworks</h1>
                </div>

                <div className='flex space-x-2 my-4 border-b'>
                  <div className=''>
                    <img className='w-[50px] h-[50px]' src={LatestArt1.image} alt={LatestArt1.title} />
                  </div>
                  <div className=' text-start'>
                    <p className='text-[13px]'>{LatestArt1.title.substring(0, 27)}</p>
                    <p className='text-[13px] '>{LatestArt1.description.substring(0, 12)}<br /></p>
                  </div>
                </div>

                <div className='flex space-x-2 my-4 border-b '>
                  <div className=''>
                    <img className='w-[50px] h-[50px]' src={LatestArt2.image} alt={LatestArt2.title} />
                  </div>
                  <div className=' text-start'>
                    <p className='text-[13px]'>{LatestArt2.title.substring(0, 27)}</p>
                    <p className='text-[13px] '>{LatestArt2.description.substring(0, 12)}<br /></p>
                  </div>
                </div>

                <ul className=" items-center justify-center md:flex md:gap-4  ">
                  <li className="px-24 py-2 bg-[#1E1E1E] text-center   rounded-sm text-white ">
                    <Link to={'/gallery'}> View all</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default Home