import React, { useState } from "react";
import Logo from '../assets/header/image 26.png'
import { FaHome, } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi2";
import { GrGallery } from "react-icons/gr"
import { RiSettings5Fill } from "react-icons/ri";
import { MdHistoryEdu } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import { CiLogout } from "react-icons/ci";
import Arrowright from '../assets/exhibition/Icon.png'
import { Link } from "react-router-dom";
import { CgMenuRight } from "react-icons/cg";

export default function Nav() {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);

    return (
        <div>
            <div className='hidden lg:block shadow-md py-10 '>
                <div className='px-6 mx-8'>
                    <Link to={'/'} >
                        <img className='w-[130px]' src={Logo} alt='Logo' />
                    </Link>
                </div>

                <div className='px-8 mt-3'>
                    <hr />
                </div>

                {/* navigation menu */}

                <div className='space-y-4'>
                    <Link to={'/admin'} className='flex justify-between gap-40 mt-12 px-8 hover:bg-[#f8f8f8] mx-8 py-4'>
                        <div className='flex gap-6'>
                            <FaHome className='scale-150' />
                            <p className='mt-[-5px] text-[19px] font-medium'>Home</p>
                        </div>
                        <div>
                            <img className='scale-150' src={Arrowright} alt='icon' />
                        </div>
                    </Link>

                    <Link to={'/adminshop'} className='flex justify-between gap-40ß px-8 hover:bg-[#f8f8f8] mx-8 py-4'>
                        <div className='flex gap-6'>
                            <HiCurrencyDollar className='scale-150' />
                            <p className='mt-[-5px] text-[19px] font-medium'>Shop</p>
                        </div>
                        <div>
                            <img className='scale-150' src={Arrowright} alt='icon' />
                        </div>
                    </Link>

                    <Link to={'/adminExhibition'} className='flex justify-between gap-40  px-8 hover:bg-[#f8f8f8] mx-8 py-4'>
                        <div className='flex gap-6'>
                            <VscGraph className='scale-150' />
                            <p className='mt-[-5px] text-[19px] font-medium'>Exhibition</p>
                        </div>
                        <div>
                            <img className='scale-150' src={Arrowright} alt='icon' />
                        </div>
                    </Link>

                    <Link to={'/admingallery'} className='flex justify-between gap-40  px-8 hover:bg-[#f8f8f8] mx-8 py-4'>
                        <div className='flex gap-6'>
                            <GrGallery className='scale-150' />

                            <p className='mt-[-5px] text-[19px] font-medium'>Gallery</p>
                        </div>
                        <div>
                            <img className='scale-150' src={Arrowright} alt='icon' />
                        </div>
                    </Link>

                    <div className='flex justify-between gap-40  px-8 hover:bg-[#f8f8f8] mx-8 py-4'>
                        <div className='flex gap-6'>
                            <MdHistoryEdu className='scale-150' />
                            <p className='mt-[-5px] text-[19px] font-medium'>History</p>
                        </div>
                        <div>
                            <img className='scale-150' src={Arrowright} alt='icon' />
                        </div>
                    </div>

                    <div className='flex justify-between gap-40 px-8 hover:bg-[#f8f8f8] mx-8 py-4'>
                        <div className='flex gap-6'>
                            <RiSettings5Fill className='scale-150' />
                            <p className='mt-[-5px] text-[19px] font-medium'>Settings</p>
                        </div>
                        <div>
                            <img className='scale-150' src={Arrowright} alt='icon' />
                        </div>
                    </div>
                </div>

                <div className='px-8  mt-16'>
                    <hr />
                </div>

                <div className="bg-[#f8f8f8] mx-8 ">
                    <button className='flex gap-3 my-9  py-6 pr-36 pl-4'>
                        <CiLogout className='scale-150' />
                        <p className='font-medium text-red-700 mt-[-5px]'>Log out</p>
                    </button>
                </div>

            </div>

            {/* hambuger */}
            <div className='lg:hidden flex justify-between  z-10 text-3xl text-black  my-10 mx-8'>
                <div className="" >
                    {!nav ? (
                        <div className='flex gap-4'>
                            <CgMenuRight onClick={handleClick} />

                        </div>
                    ) : (
                        <button onClick={handleClick} className="text-[16px]"> Close Menu </button>
                    )}
                </div>
                <div>
                    <p className="font-medium text-[16px]">Hello Admin</p>
                </div>
            </div>



            {/* mobile */}
            <div
                className={
                    !nav
                        ? 'hidden'
                        : 'absolute bg-white'
                }
            >

                <div className='  mt-8'>
                    <div className='px-6 '>
                        <img className='w-[100px]' src={Logo} alt='Logo' />
                    </div>

                    <div className='px-8 mt-3'>
                        <hr />
                    </div>

                    {/* navigation menu */}

                    <div className='space-y-4'>
                        <Link to={'/admin'} className='flex justify-between gap-40 mt-12 px-8 hover:bg-[#f8f8f8] py-4'>
                            <div className='flex gap-6'>
                                <FaHome className='scale-150' />
                                <p className='mt-[-5px] text-[19px] font-medium'>Home</p>
                            </div>
                            <div>
                                <img className='scale-150' src={Arrowright} alt='icon' />
                            </div>
                        </Link>

                        <Link to={'/adminshop'} className='flex justify-between gap-40ß  hover:bg-[#f8f8f8] mx-8 py-4'>
                            <div className='flex gap-6'>
                                <HiCurrencyDollar className='scale-150' />
                                <p className='mt-[-5px] text-[19px] font-medium'>Shop</p>
                            </div>
                            <div>
                                <img className='scale-150' src={Arrowright} alt='icon' />
                            </div>
                        </Link>

                        <Link to={'/adminExhibition'} className='flex justify-between gap-40   hover:bg-[#f8f8f8] mx-8 py-4'>
                            <div className='flex gap-6'>
                                <VscGraph className='scale-150' />
                                <p className='mt-[-5px] text-[19px] font-medium'>Exhibition</p>
                            </div>
                            <div>
                                <img className='scale-150' src={Arrowright} alt='icon' />
                            </div>
                        </Link>

                        <div className='flex justify-between gap-40  hover:bg-[#f8f8f8] mx-8 py-4'>
                            <div className='flex gap-6'>
                                <GrGallery className='scale-150' />

                                <p className='mt-[-5px] text-[19px] font-medium'>Gallery</p>
                            </div>
                            <div>
                                <img className='scale-150' src={Arrowright} alt='icon' />
                            </div>
                        </div>

                        <div className='flex justify-between gap-40  hover:bg-[#f8f8f8] mx-8 py-4'>
                            <div className='flex gap-6'>
                                <MdHistoryEdu className='scale-150' />
                                <p className='mt-[-5px] text-[19px] font-medium'>History</p>
                            </div>
                            <div>
                                <img className='scale-150' src={Arrowright} alt='icon' />
                            </div>
                        </div>

                        <Link className='flex justify-between gap-40 hover:bg-[#f8f8f8] mx-8 py-4'>
                            <div className='flex gap-6'>
                                <RiSettings5Fill className='scale-150' />
                                <p className='mt-[-5px] text-[19px] font-medium'>Settings</p>
                            </div>
                            <div>
                                <img className='scale-150' src={Arrowright} alt='icon' />
                            </div>
                        </Link>
                    </div>

                    <div className='px-8  mt-16'>
                        <hr />
                    </div>


                    <button className='flex gap-3 mx-8 my-9  py-6 pr-36 pl-4'>
                        <CiLogout className='scale-150' />
                        <p className='font-medium text-red-700 mt-[-5px]'>Log out</p>
                    </button>
                </div>
            </div>
        </div>
    )
}
