import React from 'react'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'
import Icon from '../assets/bookticket/icon.png'
import Icon2 from '../assets/bookticket/icon2.png'
import { Link } from 'react-router-dom'

export const BookTicket = () => {
    return (
        <div>
            <MenuBar />
            <div className='mt-16 items-center justify-center text-center mx-4 xl:mx-40 '>
                {/* <ShoppingCart /> */}
                <div>
                    <h1 className='text-[#F88603] mx-10 text-[55px] lg:text-[75px] lg:font-extrabold font-bold leading-[60px] tracking-[13px]'>Plan Your Visit</h1>
                </div>
                <div className='lg:mx-[270px] text-[19px] lg:text-[15px] text-[#818285] py-10'>
                    <p>Come explore our museum any day of the week. Start by getting your one-day pass or full year pass
                    </p>
                </div>
                <hr />
            </div>


            {/* {one visit} */}
            <div className='my-20 lg:grid lg:grid-cols-2 border border-[#2F3035] mx-4  lg:mx-32'>
                <div className='lg:pt-28  px-4 lg:px-14 py-20'>
                    <p className='border p-2 px-4 border-[#2F3035] w-20 rounded tracking-widest text-center text-[14px] font-bold bg-slate-200'>N5000</p>
                    <h1 className='text-[32px] font-bold tracking-widest py-4'>One Time Visit</h1>
                    <p className='text-[13px] w-[80%]'>
                        Explore our museum with a one-time visit ticket and dive into a world of art, history, and culture. Get your ticket now and start your journey!
                    </p>
                    <div className='mt-14'>
                        <Link to='/onetimevisit' className='bg-[#2A564C] rounded text-[#FBFCFD] px-20 py-4 '>Book Ticket
                        </Link>
                    </div>
                </div>


                <div className='bg-[#171D1A] text-[#FFF] py-20 px-8' >
                    <div className=' lg:mx-10'>
                        <h1 className='font-semibold pb-4 text-[26px]'>BENEFITS:</h1>
                        <div className='flex py-4'>
                            <img src={Icon} alt='icon' />
                            <p className='mx-2'>ACCESS TO ALL EXHIBITIONS</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon} alt='icon' />
                            <p className='mx-2'>GUIDED TOURS</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon} alt='icon' />
                            <p className='mx-2'>FREE TOUR PAMPHLET</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon} alt='icon' />
                            <p className='mx-2'>30% DISCOUNT IN MERCH</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon} alt='icon' />
                            <p className='mx-2'>PRIORITY ENTRY</p>
                        </div>

                    </div>

                </div>
            </div>

{/* {annual visit} */}
            <div className='my-20 lg:grid lg:grid-cols-2 border border-[#2F3035] mx-4 lg:mx-32'>
                <div className='lg:pt-28 px-4 lg:px-14 py-20 bg-[#2A564C]'>
                    <p className='border p-2 px-4 border-[#2F3035] w-24 rounded tracking-widest text-center text-[14px] font-bold bg-slate-200'>N20000</p>
                    <h1 className='text-[32px] text-[#ffffff] font-bold tracking-widest py-4'>Annual Visit</h1>
                    <p className='text-[13px] w-[80%] text-[#ffffff]'>
                        Explore our museum with a one-time visit ticket and dive into a world of art, history, and culture. Get your ticket now and start your journey!
                    </p>
                    <div className='mt-14'>
                        <Link to={'/anualvisit'} className='bg-[#ffffff] rounded text-[#2A564C] px-20 py-4 '>Book Ticket
                        </Link>
                    </div>
                </div>


                <div className=' text-[#000] py-20 px-4' >
                    <div className='lg:mx-10'>
                        <h1 className='font-semibold pb-4 text-[26px]'>BENEFITS:</h1>
                        <div className='flex py-4'>
                            <img src={Icon2} alt='icon' />
                            <p className='mx-2'>ACCESS TO ALL EXHIBITIONS</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon2} alt='icon' />
                            <p className='mx-2'>GUIDED TOURS</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon2} alt='icon' />
                            <p className='mx-2'>ACCESS TO EVENTS & EXHIBITIONS</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon2} alt='icon' />
                            <p className='mx-2'>FREE TOUR PAMPHLET</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon2} alt='icon' />
                            <p className='mx-2'>30% DISCOUNT IN MERCH</p>
                        </div>
                        <div className='flex py-4'>
                            <img src={Icon2} alt='icon' />
                            <p className='mx-2'>PRIORITY ENTRY</p>
                        </div>

                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
