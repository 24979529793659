import React from 'react'
import { useParams } from 'react-router-dom'
import Nav from './Nav'
import Image1 from '../assets/admin/image 12.png'
import Product from '../pages/giftshop/Product.json'
import { Link } from 'react-router-dom'
import Car from '../assets/giftshop/image 23.png'
import Card from '../assets/giftshop/credit-card.png'
import Phone from '../assets/giftshop/phone-call.png'
import Arrow from '../assets/admin/Arrow 1.png'

function AdminShopProductDetail() {

    const { id } = useParams()
    const product = Product.find(item => {
        return item.id === parseInt(id)
    })

    if (!product) {
        return <section>Loading....</section>
    }
    const { title, image, price, description } = product
    return (
        <div className='lg:flex'>
            <div>
                <Nav />
            </div>
            <div className='w-full'>

                <div className=' mx-14 my-4 flex text-justify justify-between'>
                    <div><p className='font-medium'>Home</p></div>
                    <div><p className='font-medium'>Hello Admin</p></div>
                </div>

                <div className='flex justify-between bg-[#FBEBD6] mt-8'>
                    <div className='p-8'>
                        <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>The Giftshop</h1>
                        <p className='font-medium text-[12px] pt-2 text-[#818285]'>Here's all you need to know about the museum at a glance</p>
                    </div>
                    <div className='mt-8 md:mt-0 mx-2'>
                        <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
                    </div>
                </div>

                <div className='flex justify-between mx-4 lg:mx-20 mt-14'>
                    <div>
                      <Link to={'/adminshop'}>
                      <img src={Arrow} alt='arrow' />
                      </Link>
                    </div>
                    <div>
                        <Link className='bg-[#2A564C] text-white py-2 px-6 text-center'>
                        Edit Info
                        </Link>
                    </div>
                </div>

                {/* product detail starts here */}
                <div className='lg:grid grid-cols-2  justify-center lg:mx-32 mt-14' >
                    <div className='mx-8'>
                        <div className='lg:flex items-center justify-center '>
                            <img className='w-[350px] h-[350px]' src={image} alt={title} />
                        </div>
                        <div className='flex  lg:items-center lg:justify-center mt-4 space-x-4'>
                            <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
                            <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
                            <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
                            <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
                        </div>
                    </div>

                    <div className='pt-8 lg:pt-0 max-w-md mx-10'>
                        <h1 className='text-[#2A564C] font-extrabold text-2xl tracking-wider mb-8'>{title}</h1>
                        <p className='text-[#818285] text-[12px]'>{description}</p>
                        <p className='py-4 text-[#2A564C] font-extrabold text-[18px]'>${price}</p>
                        <div className='mt-8 flex'>


                        </div>
                        <hr className='mt-2' />
                        <div className='mt-6'>

                            <div className='flex'>
                                <img className='' src={Car} alt='car' /> <p className='text-[11px] px-2'> Free Shipping</p>
                            </div>

                            <div className='flex my-2'>
                                <img className='' src={Card} alt='car' /> <p className='text-[11px] px-4'> Secure Payment</p>
                            </div>

                            <div className='flex my-2'>
                                <img className='' src={Phone} alt='car' /> <p className='text-[11px] px-4'> 24 / 7 Help Center</p>
                            </div>

                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminShopProductDetail