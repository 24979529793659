import React from 'react'
import NavRouter from './components/NavRouter';
// import Header from './components/Header';
//import MenuBar from './components/MenuBar';
import { BrowserRouter as Router } from 'react-router-dom'
//import { motion } from 'framer-motion'

const App = () => {
  return (
    <div>
      <Router>

      <NavRouter />
      </Router>
    </div>
  )
} 

export default App