import React from 'react'
import Calender from '../assets/bookticket/ph_calendar-light.png'
//import Icon2 from '../assets/bookticket/icon2.png'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import Icon2 from '../assets/bookticket/icon2.png'
import Accessimage from '../assets/bookticket/accesstoall.png'
import Guidedtours from '../assets/bookticket/guidedtour.png'
import Pamphlet from '../assets/bookticket/pamphlet.png'
import Discounticon from '../assets/bookticket/discount.png'
import Allevent from '../assets/bookticket/allevents.png'

const AnualVisitBookTicket = () => {
    return (
        <div>
            <MenuBar />
            <div>
                <div className=' lg:grid lg:grid-cols-2 items-center  bg-[#171D1A] pb-44 '>
                    <div className='lg:ml-32 pt-8 lg:pt-0 ml-8  '>
                        <p className='float-left text-white text-[18px]'><Link to={'/'}>Home</Link> / <Link to={'/bookticket'}>Ticket</Link> / <span className='text-[#F88603]'>annual-visit</span></p>
                    </div>
                    <div>
                        <img className='w-[200px]' src={Calender} alt='icon' />
                    </div>
                </div>


                <div className='mt-[-180px] lg:mt-[-16%]  lg:grid lg:grid-cols-2 border border-[#171D1A] mx-4 lg:mx-32 mb-20'>
                    <div className='bg-[#2A564C] lg:pt-28 px-4 lg:px-14 py-20 '>
                        <p className='border p-2 px-4 border-[#171D1A] w-24 rounded tracking-widest text-center text-[14px] font-bold bg-slate-200'>N20000</p>
                        <h1 className='text-[32px] text-[#ffffff] font-bold tracking-widest py-4'>Annual Visit</h1>
                        <p className='text-[13px] w-[80%] text-[#FFFFFF]'>
                        Become family and join our community with an annual pass. This gives you license to priority entry, unhindered access to every public part of the museum, public exhibitions, guided tours, merch discounts, free study materials and community updates on our history and current events

                        Throughout the year

                        </p>
                        <div className='mt-14'>
                            <Link to={'/onetimepasscheckout'} className='bg-[#ffffff] rounded text-[#2A564C] px-20 py-4 '>Book Ticket
                            </Link>
                        </div>
                    </div>


                    <div className='bg-[#fff] text-[#000] py-20 px-8 lg:px-20' >
                        <div className='lg:mx-10'>
                            <h1 className='font-semibold pb-4 text-[26px]'>BENEFITS:</h1>
                            <div className='flex py-4'>
                                <img src={Icon2} alt='icon' />
                                <p className='mx-2'>ACCESS TO ALL EXHIBITIONS</p>
                            </div>
                            <div className='flex py-4'>
                                <img src={Icon2} alt='icon' />
                                <p className='mx-2'>GUIDED TOURS</p>
                            </div>
                            <div className='flex py-4'>
                                <img src={Icon2} alt='icon' />
                                <p className='mx-2'>ACCESS TO EVENTS & EXHIBITIONS</p>
                            </div>
                            <div className='flex py-4'>
                                <img src={Icon2} alt='icon' />
                                <p className='mx-2'>FREE TOUR PAMPHLET</p>
                            </div>
                            <div className='flex py-4'>
                                <img src={Icon2} alt='icon' />
                                <p className='mx-2'>30% DISCOUNT IN MERCH</p>
                            </div>
                            <div className='flex py-4'>
                                <img src={Icon2} alt='icon' />
                                <p className='mx-2'>PRIORITY ENTRY</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='bg-[#171D1A] p-8'></div>

                <div className='lg:flex items-center justify-center lg:space-x-8 py-28 lg:mx-20 space-y-2 md:mx-16 md:space-y-4 lg:space-y-0 mx-4'>

                    <div className='text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16  rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3]  md:py-[6%] mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img className='' src={Accessimage} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>ACCESS TO ALL EXHIBITS</h1>
                        <p className='text-[15px]'>Explore every corner of our museum, from permanent collections to special exhibitions, and experience a diverse range of art, history, and culture. </p>
                    </div>

                    <div className='text-center items-center lg:min-w-[300px] h-[400px]    lg:w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>

                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Guidedtours} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>GUIDED TOURS</h1>
                        <p className='text-[15px]'>Join our expert guides on informative tours that offer deeper insights into our most popular exhibits, enhancing your visit with fascinating stories and details. </p>
                    </div>

                    <div className='text-center items-center lg:min-w-[300px]  h-[400px]  lg:max-w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>

                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Pamphlet} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>FREE TOUR PAMPHLETS</h1>
                        <p className='text-[15px]'>Receive a complimentary pamphlet that provides valuable information about the exhibits, helping you navigate and appreciate the museum’s highlights at your own pace.</p>
                    </div>
                    <div className='lg:hidden text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16  rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img className='' src={Discounticon} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>3% DISCOUNT IN MERCH</h1>
                        <p className='text-[15px]'>Enjoy a 3% discount on all purchases at our museum shop, where you can find unique souvenirs, books, and gifts to commemorate your visit.</p>
                    </div>

                    <div className='lg:hidden text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16  rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] md:py-[6%]  mx-[40%] py-[3%] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img className='' src={Allevent} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>ACCESS TO ALL EVENTS AND EXHIBITIONS</h1>
                        <p className='text-[15px]'>Be a part of all our events and exhibitions and explore the beauty of art and culture</p>
                    </div>
                </div>


                {/* {second} */}
                <div className='hidden lg:flex items-center justify-center lg:space-x-16 pb-20 lg:mx-28'>
                    <div className='text-center items-center lg:min-w-[300px] h-[40
                0px] lg:w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Discounticon} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>3% DISCOUNT IN MERCH</h1>
                        <p className='text-[15px]'>Enjoy a 3% discount on all purchases at our museum shop, where you can find unique souvenirs, books, and gifts to commemorate your visit.</p>
                    </div>

                    <div className='text-center items-center lg:min-w-[300px] h-[400px] lg:w-[30%] justify-center border border-[#171D1A] py-16 rounded px-8 '>
                        <div className='flex items-center justify-center bg-[#E6F6F3] mb-6 rounded-full lg:mx-[33%] lg:p-[10%] border border-[#171D1A] '>
                            <img src={Allevent} alt={'IMG'} />
                        </div>
                        <h1 className='font-semibold text-[18px] pb-4'>ACCESS TO ALL EVENTS AND EXHIBITIONS</h1>
                        <p className='text-[15px]'>Be a part of all our events and exhibitions and explore the beauty of art and culture </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AnualVisitBookTicket 