import React from 'react'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import Footerlogo from '../assets/footer/Logo.png'
import Twitter from '../assets/footer/Twitter.png'
import Facebook from '../assets/footer/Facebook.png'
import Linkedin from '../assets/footer/Linkedin.png'
import Sport from '../assets/footer/Sport.png'
import Svg from '../assets/footer/Social icon (2).png'
import Github from '../assets/footer/Github.png'

const Footer = () => {
    return (
        <div>
            {/* {mobile footer} */}
            <div className=' md:hidden'>
                <div className='bg-[#C3D0CD] py-16 '>
                    <div className='px-4'>
                        <h1 className='text-[#1A1C21] text-[38px]  font-bold leading-[40px] tracking-[5px]'>subscribe <br /> to our <br /> news letter</h1>
                    </div>

                    <div>
                        <form className='pt-8 mx-4 space-y-6'>
                            <div>
                                <input className='pl-2 h-12 mt-3 w-full' type='text' placeholder='Enter your email here ' />
                            </div>
                            <button className='flex text-[20px] py-4 text-center items-center justify-center w-full rounded-sm text-white bg-[#2A564C]'>submit<FaHeart className='ml-3 mt-1' /></button>
                        </form>
                    </div>
                </div>


                <div className='bg-[#20413A] py-20'>
                    <div className='px-4'>
                        <Link to={'/'} >
                            <img className="" src={Footerlogo} alt="logo" />
                        </Link>
                        <p className='text-[#BFBFC1] pt-3'>History through art</p>
                    </div>

                    <div className='my-16 text-[#D3D4D5] grid grid-cols-2 text-center'>
                        <ul className='space-y-4 '>
                            <li className='text-white'><Link>Museum</Link></li>
                            <li><Link to={'/'}>Home</Link></li>
                            <li><Link to={'/gallery'}>Gellery</Link></li>
                            <li><Link to={'/about'}>About us</Link></li>
                            <li><Link to={'/history'}>Our history</Link></li>
                        </ul>
                        <ul className='space-y-4'>
                            <li className='text-white'><Link>Resources</Link></li>
                            <li><Link to={'/giftshop'}>Gift shop</Link></li>
                            <li><Link to={'/exhibition'}>Exhibitions</Link></li>
                            <li><Link to={'/login'}>Contact us</Link></li>
                       
                        </ul>
                    </div>
                    {/* {vertical line} */}
                    <div className='border-b-2 border-[#BFBFC1] mx-4'></div>

                    <div>
                    <div className='text-center py-10' ><h1 className='text-[#AAABAD] text-[15px]'>© 2024 Isi Nri. All rights reserved.</h1></div>
                    <div className='flex space-x-10 text-white xl items-center justify-center'>
                        <Link to={'/'} >
                            <img className="" src={Twitter} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Facebook} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Linkedin} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Github} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Sport} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Svg} alt="logo" />
                        </Link>
                    </div>
                </div>
                </div>

            </div>





            {/* {desktop newsletter and footer} */}
            <div className='hidden md:flex justify-between bg-[#C3D0CD] py-[64px] px-24'
            >
                {/* {text} */}
                <div>
                    <h1 className='text-[#1A1C21] md:text-[30px] lg:text-[38px] font-medium leading-[40px] tracking-widest'>subscribe <br /> to our news letter</h1>
                </div>
                {/* {form input} */}
                <div>
                    <form className='flex'>
                        <div>
                            <input className='pl-2 h-8 mt-3 w-[250px]' type='text' placeholder='Enter your email here ' />
                        </div>
                        <button className='flex ml-4 py-4 px-4  rounded-sm text-white bg-[#2A564C]'>submit<FaHeart className='ml-3 mt-1' /></button>
                    </form>
                </div>
            </div>

            {/* {footer} */}
            <div className='hidden md:block bg-[#20413A] py-16 '>
                <div className='flex px-24 pb-10'>
                    <div>
                        <Link to={'/'} >
                            <img className='hover:scale-95 duration-100' src={Footerlogo} alt="logo" />
                        </Link>
                       
                    </div>
                    <div className='text-[#BFBFC1] grid grid-cols-2 gap-12 items-center justify-center mx-36'>
                        <ul className='space-y-4 '>
                            <li className='text-white'><Link>Museum</Link></li>
                            <li><Link to={'/'}>Home</Link></li>
                            <li><Link to={'/gallery'}>Gellery</Link></li>
                            <li><Link to={'/abput'}>About us</Link></li>
                            <li><Link to={'/history'}>Our history</Link></li>
                        </ul>
                        <ul className='space-y-4 mt-[-23px]'>
                            <li className='text-white'><Link>Resources</Link></li>
                            <li><Link to={'/giftshop'}>Gift shop</Link></li>
                            <li><Link to={'/exhibition'}>Exhibitions</Link></li>
                            <li><Link to={'/login'}>Contact us</Link></li>
                            <li><Link></Link></li>
                        </ul>
                    </div>
                </div>

                {/* {vertical line} */}
                <div className='border-b-2 border-[#BFBFC1] mx-24'></div>
                {/* {social icons} */}
                <div className='mx-24 flex justify-between pt-4'>
                    <div><h1 className='text-[#AAABAD] text-[15px]'>© 2024 Isi Nri. All rights reserved.</h1></div>
                    <div className='flex space-x-4'>
                        <Link to={'/'} >
                            <img className="" src={Twitter} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Facebook} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Linkedin} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Github} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Sport} alt="logo" />
                        </Link>
                        <Link to={'/'} >
                            <img className="" src={Svg} alt="logo" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer