import React from "react";
import MenuBar from "../components/MenuBar";
import { useParams } from "react-router-dom";
import Data from "../data/history.json";
import Footer from "../components/Footer";

function HistoryDetail() {
  const { id } = useParams();
  const MoreDail = Data.find((item) => {
    return item.id === parseInt(id);
  });
  if (!MoreDail) {
    return <section>Loading....</section>;
  }
  return (
    <div>
      <MenuBar />

      <div className="lg:flex lg:space-x-4 lg:justify-center py-10 mx-2">
        <div>
          <img
            className="lg:w-[400px] xl:w-[600px] lg:h-[200px]"
            src={MoreDail.image}
            alt="director_image"
          />
        </div>
        <div className="lg:w-[550px] lg:pt-0 pt-2">
          <h1 className="font-extrabold pb-4">{MoreDail.title}</h1>
          <p>{MoreDail.description}</p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HistoryDetail;
