import React, { useState } from "react";
import MenuBar from "../components/MenuBar";
import Footer from "../components/Footer";
import Image1 from "../assets/about/image4.png";
import Image2 from "../assets/about/Director.jpeg";
import Image3 from "../assets/home/homeimage3.png";
import Image4 from "../assets/home/homeimage4.png";
import Arrow from "../assets/about/Icon.svg";
import { Link } from "react-router-dom";
import Data from "../data/history.json";
import FestiveImage from "../assets/about/history.jpg";

function LearnHistory() {
  const [active, setActive] = useState("2022");
  //const [changeColor, setChangeColor] = useState(false)

  //function for handleClick
  // const handleClick = () => {
  //   setChangeColor(!changeColor)
  // }
  return (
    <div>
      <MenuBar />
      <div>
        <div className="text-center mt-10">
          <div>
            <h1 className="text-[#1A1C21] text-6xl  xl:text-[75px] lg:text-[75px] lg:font-extrabold font-bold xl:eading-[60px] xl:tracking-[12px] tracking-[3px]">
              Learn our Museum
            </h1>
          </div>
          <div className="lg:mx-[270px] text-[19px] lg:text-[15px]  text-center mx-4 text-[#818285] py-10">
            <p className=" ">
              Explore our past to understand our present and shape our future.
              Discover the stories, events, and people that have shaped who we
              are today.
            </p>
          </div>

          {/* <div className='boder-4'></div> */}
        </div>

        {/* {main grid} */}
        <div className="lg:bg-[#1A1C21]">
          <div className="lg:flex lg:mx-4 xl:mx-28 lg:gap-8 py-8 lg:py-20 mx-4">
            <div className="hidden lg:block text-[#F6F8F8]">
              <div>
                <img
                  className="w-[1100px] h-[400px]"
                  src={Image1}
                  alt="director_image"
                />
              </div>
              <div className="">
                <h1 className="text-[#F6F8F8] py-8 font-extrabold text-3xl tracking-widest">
                  People. History. Unity.
                </h1>
                <div className="w-[450px]">
                  <p>
                  To remember the history of our people and 
                  unite to build a stronger future where the
                   identity of our children is not lost
                  </p>
                </div>
              </div>
              <div className="flex justify-center rounded border-2 border-[#F6F8F8] my-10 py-2 p-1 w-[200px]">
                <Link to={'/about'}>Read more</Link>
                <img src={Arrow} alt="icon" />
              </div>
            </div>

            {/* {second} */}
            <div className="space-y-10 lg:text-[#F6F8F8]">
              {Data.map((va) => (
                <div className="lg:flex lg:space-x-4">
                  <div>
                    <img
                      className="lg:w-[400px] xl:w-[600px] lg:h-[200px]"
                      src={va.image}
                      alt="director_image"
                    />
                  </div>
                  <div className="lg:w-[550px] lg:pt-0 pt-2">
                    <h1 className="font-extrabold pb-4">{va.title}</h1>
                    <p>{va.description.substring(0, 50)}</p>

                    <Link to={`/history/${va.id}`} className="font-extrabold">
                      <div className="flex py-6">
                        Read more
                        <img src={Arrow} alt="icon" />
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* {click todisplay history} */}
        <div className="bg-[#1A1C21] pt-16 lg:py-56">
          <div className="lg:flex justify-center lg:space-x-16">
            <div className="flex justify-center space-x-8 lg:space-x-0 lg:flex-col mb-14 border-b border-white lg:border-b-0 pb-4 lg:pb-0 mx-4 lg:mx-0">
              <div>
                <div
                  className={`cursor-pointer ${
                    active === "2022" ? "text-white" : "text-[#58595D]"
                  }`}
                  onClick={() => setActive("2022")}
                >
                  <div
                    className={`hidden lg:block border-l-2 py-14 mb-[-40px] ${
                      active === "2022"
                        ? "border-white text-yellow-600"
                        : "border-[#58595D]"
                    }`}
                  ></div>
                  <p className="hidden lg:block ml-[-7px] font-extrabold text-[24px] lg:text-2xl">
                    <b className="text-6xl lg:mr-3">.</b>2022
                  </p>
                  <p className=" lg:hidden font-extrabold text-[18px]">
                    2022<b className="text-4xl ml-1 mb-[17px]">.</b>
                  </p>
                </div>
              </div>

              <div>
                <div
                  className={`cursor-pointer ${
                    active === "2018" ? "text-white" : "text-[#58595D]"
                  }`}
                  onClick={() => setActive("2018")}
                >
                  <div
                    className={`hidden lg:block border-l-2 py-14 mb-[-40px] ${
                      active === "2018" ? "border-white" : "border-[#58595D]"
                    }`}
                  ></div>
                  <p className="hidden lg:block ml-[-7px] font-extrabold text-[24px] lg:text-2xl">
                    <b className="text-6xl lg:mr-3">.</b>2018
                  </p>
                  <p className=" lg:hidden font-extrabold text-[18px]">
                    2018<b className="text-4xl ml-1 mb-[17px]">.</b>
                  </p>
                </div>
              </div>

              <div>
                <div
                  className={`cursor-pointer ${
                    active === "2000" ? "text-white" : "text-[#58595D]"
                  }`}
                  onClick={() => setActive("2000")}
                >
                  <div
                    className={`hidden lg:block border-l-2 py-14 mb-[-40px] ${
                      active === "2000" ? "border-white" : "border-[#58595D]"
                    }`}
                  ></div>
                  <p className="hidden lg:block ml-[-7px] font-extrabold text-[24px] lg:text-2xl">
                    <b className="text-6xl lg:mr-3">.</b>2000
                  </p>
                  <p className=" lg:hidden font-extrabold text-[18px]">
                    2000<b className="text-4xl ml-1 mb-[17px]">.</b>
                  </p>
                </div>
              </div>

              <div>
                <div
                  className={`cursor-pointer ${
                    active === "1998" ? "text-white" : "text-[#58595D]"
                  }`}
                  onClick={() => setActive("1998")}
                >
                  <div
                    className={`hidden lg:block border-l-2 py-14 mb-[-40px] ${
                      active === "1998" ? "border-white" : "border-[#58595D]"
                    }`}
                  ></div>
                  <p className="hidden lg:block ml-[-7px] font-extrabold text-[24px] lg:text-2xl">
                    <b className="text-6xl lg:mr-3">.</b>1998
                  </p>
                  <p className=" lg:hidden font-extrabold text-[18px]">
                    1998<b className="text-4xl ml-1 mb-[17px]">.</b>
                  </p>
                </div>
              </div>
            </div>

            <div className="mx-4 lg:mx-0">
              {active === "2022" && (
                <div
                  className="lg:flex items-center justify-center lg:gap-10"
                  title="2022"
                >
                  {/* {text} */}
                  <div className="text-[#F6F8F8] lg:w-[400px]">
                    <h1 className="text-5xl text-white border-b pb-4 font-extrabold tracking-[-1px]">
                      2022
                    </h1>
                    <p className="py-8">
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>

                    <p>
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>
                  </div>

                  {/* {images} */}
                  <div className="flex justify-center py-20 lg:py-0 lg:pt-0">
                    <img
                      className="lg:w-[520px]  w-full  lg:h-[500px]"
                      src={FestiveImage}
                      alt="director_image"
                    />
                  </div>
                </div>
              )}

              {active === "2018" && (
                <div
                  className="lg:flex items-center justify-center lg:gap-10"
                  title="2018"
                >
                  {/* {text} */}
                  <div className="text-[#F6F8F8] lg:w-[400px]">
                    <h1 className="text-5xl text-white border-b pb-4 font-extrabold tracking-[-1px]">
                      2018
                    </h1>
                    <p className="py-8">
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>

                    <p>
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>
                  </div>

                  {/* {images} */}
                  <div className="flex justify-center py-20 lg:py-0 lg:pt-0">
                    <img
                      className="lg:w-[520px] w-full  h-[500px]"
                      src={Image2}
                      alt="director_image"
                    />
                  </div>
                </div>
              )}

              {active === "2000" && (
                <div
                  className="lg:flex items-center justify-center lg:gap-10"
                  title="2000"
                >
                  {/* {text} */}
                  <div className="text-[#F6F8F8] lg:w-[400px]">
                    <h1 className="text-5xl text-white border-b pb-4 font-extrabold tracking-[-1px]">
                      2000
                    </h1>
                    <p className="py-8">
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>

                    <p>
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>
                  </div>

                  {/* {images} */}
                  <div className="flex justify-center py-20 lg:py-0 lg:pt-0">
                    <img
                      className="lg:w-[520px] w-full  h-[500px]"
                      src={Image4}
                      alt="director_image"
                    />
                  </div>
                </div>
              )}

              {active === "1998" && (
                <div
                  className="lg:flex items-center justify-center lg:gap-10"
                  title="1998"
                >
                  {/* {text} */}
                  <div className="text-[#F6F8F8] lg:w-[400px]">
                    <h1 className="text-5xl text-white border-b pb-4 font-extrabold tracking-[-1px]">
                      1998
                    </h1>
                    <p className="py-8">
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>

                    <p>
                      To inspire curiosity, foster learning, and connect people
                      through the power of art, history, and culture. We aim to
                      create an inclusive space where diverse stories are told,
                      creativity is celebrated, and everyone feels welcome.
                    </p>
                  </div>

                  {/* {images} */}
                  <div className="flex justify-center py-20 lg:py-0 lg:pt-0">
                    <img
                      className="lg:w-[520px] w-full  h-[500px]"
                      src={Image3}
                      alt="director_image"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnHistory;
