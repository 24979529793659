import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import MenuBar from '../components/MenuBar';
import Footer from '../components/Footer';
import Popup from '../components/Notification';

function AttendanceLog() {
 const [formData, setFormData] = useState({
  fullName: '',
  phoneNumber: '',
  email: '',
  dateOfVisit: '',
 });

 const [isLoading, setIsLoading] = useState(false);
 const [error, setError] = useState('');
 const [showErrorModal, setShowErrorModal] = useState(false);
 const [successMessage, setSuccessMessage] = useState('');
 const [showSuccessModal, setShowSuccessModal] = useState(false);
 const modalRef = useRef(null);

 useEffect(() => {
  let timer;
  if (showErrorModal) {
   timer = setTimeout(() => {
    setShowErrorModal(false);
   }, 10000);
  }

  const handleClickOutside = (event) => {
   if (modalRef.current && !modalRef.current.contains(event.target)) {
    setShowErrorModal(false);
   }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
   clearTimeout(timer);
   document.removeEventListener('mousedown', handleClickOutside);
  };
 }, [showErrorModal]);

 const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
 };

 const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setIsLoading(true);

  try {
   const response = await axios.post(
    'https://isinrimuseum-backend.fly.dev/api/v1/attendances',
    {
     name: formData.fullName,
     phone: formData.phoneNumber,
     email: formData.email,
     dateOfVisit: new Date(formData.dateOfVisit),
    }
   );
   console.log('Response:', response);
   if (response.status === 201) {
    setSuccessMessage('Attendance recorded successfully!');
    setShowSuccessModal(true);
    setFormData({ fullName: '', phoneNumber: '', email: '', dateOfVisit: '' });
   }
  } catch (error) {
   setError(error.message || 'An error occurred. Please try again later.');
   setShowErrorModal(true);
   console.error('Error:', error);
  } finally {
   setIsLoading(false);
  }
 };

 return (
  <div>
   <MenuBar />
   {(showErrorModal || showSuccessModal) && (
    <div className='fixed top-2 mt-[100px] right-1/4 z-50' ref={modalRef}>
     <Popup
      type={showSuccessModal ? 'success' : 'error'}
      message={showSuccessModal ? successMessage : error}
      onClose={() => {
       setShowErrorModal(false);
       setShowSuccessModal(false);
      }}
     />
    </div>
   )}

   {isLoading && (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
     <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white'></div>
    </div>
   )}

   <div className=' py-8 h-fit'>
    <div className='shadow-lg items-center justify-center mx-4 md:mx-[8%] lg:mx-[25%] lg:p-8 rounded-md'>
     <div className='py-6 '>
      <div className='text-center'>
       <h1 className='text-[#1A1C21]  font-bold md:tracking-[6px]  tracking-widest mx-1 text-2xl lg:text-[34px] '>
        Museum Attendant
       </h1>
       <p className='text-[#2F3035] text-[13px] pt-2 md:text-[19px]'>
        Please enter your details to continue
       </p>
      </div>
     </div>
     <form className='mx-6 lg:mx-28' onSubmit={handleSubmit}>
      <label>
       <input
        type='text'
        required
        name='fullName'
        value={formData.fullName}
        onChange={handleChange}
        placeholder='Full Name'
        className='placeholder:text-[14px] py-4 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
       />
      </label>

      <label>
       <input
        type='text'
        required
        name='phoneNumber'
        value={formData.phoneNumber}
        onChange={handleChange}
        placeholder='Enter Phone Number'
        className='placeholder:text-[14px] py-4 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
       />
      </label>

      <label>
       <input
        type='email'
        name='email'
        value={formData.email}
        onChange={handleChange}
        placeholder='Email (optional)'
        className='placeholder:text-[14px] py-4 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
       />
      </label>

      <div className='text-[#1A1C21] text-[14px] md:text-[18px] mt-5'>
       Date of Visit
      </div>
      <label>
       <input
        type='date'
        required
        name='dateOfVisit'
        value={formData.dateOfVisit}
        onChange={handleChange}
        min={new Date().toISOString().split('T')[0]}
        className='placeholder:text-[14px] py-4 placeholder:text-[#1A1C21] w-full md:placeholder:text-[18px] border-b outline-[#6C6E71] my-2 lg:my-6'
       />
      </label>

      <div className='my-14 md:my-20 pb-8'>
       <button
        type='submit'
        className='bg-[#2A564C] w-full text-white py-2 md:py-5 rounded lg:text-2xl '
       >
        Register
       </button>
      </div>
     </form>
    </div>
   </div>
   <Footer />
  </div>
 );
}

export default AttendanceLog;
