import React, { useLayoutEffect } from 'react'
//import ScrollToTop from './ScrollToTop'
//import pages
import Home from '../pages/Home'
import About from '../pages/About'
import Signup from '../pages/Signup'
import Login from '../pages/Login'
import Giftshop2 from '../pages/Giftshop2'
import Detail from '../pages/Detail'
import { BookTicket } from '../pages/BookTicket'
import OneTimeVisitBookTicket from '../pages/OneTimeVisitBookTicket'
import AnualVisitBookTicket from '../pages/AnualVisitBookTicket'
import OneTimePassChecOut from '../pages/OneTimePassChecOut'
import Gallery from '../pages/Gallery'
import GalleryImageDetail from '../pages/GalleryImageDetail'
import Exhibition from '../pages/Exhibition'
import DetailExhibition from '../pages/DetailExhibition'
import AllExhibition from '../pages/AllExhibition'
import AllExhibitionDetailPage from '../pages/AllExhibitionDetailPage'
import LearnHistory from '../pages/LearnHistory'
import GiftShopPayout from '../pages/GiftShopPayout'
import Donate from '../pages/Donate'
import AttendanceLog from '../pages/AttendanceLog'
import HistoryDetail from '../pages/HistoryDetail'
import Admin from '../admin/AdminHomePage'
import AdminShopPage from '../admin/AdminShopPage'
import AdminShopProductDetail from '../admin/AdminShopProductDetail'
import AdminAddProducttoGiftShop from '../admin/AdminAddProducttoGiftShop'
import AdminExhibitionPage from '../admin/AdminExhibitionPage'
import AdminExhDetailPage from '../admin/AdminExhDetailPage'
import AddNewExhibition from '../admin/AddNewExhibition'
import AdminGalleryPage from '../admin/AdminGalleryPage'

//import routes, route & uselocation
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
//import { wait } from '@testing-library/user-event/dist/utils'


const NavRouter = () => {
    const location = useLocation();
    useLayoutEffect (() =>{
        window.scrollTo(0, 0);
    }, [location.pathname])
    return (
        <AnimatePresence initial={true} >
            <Routes key={location.pathname} location={location}>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/login' element={<Login />} />
                <Route path='/giftshop' element={<Giftshop2 />} />
                <Route path='/giftshop/:id' element={<Detail/>} />
                <Route path='/bookticket' element={<BookTicket />} />
                <Route path='/onetimevisit' element={<OneTimeVisitBookTicket />} />
                <Route path='/anualvisit' element={<AnualVisitBookTicket />} />
                <Route path='/onetimepasscheckout' element={<OneTimePassChecOut />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/gallery/:id' element={<GalleryImageDetail />} />
                <Route path='/exhibition' element={<Exhibition />} />
                <Route path='/exhibition/:id' element={<DetailExhibition />} />
                <Route path='/allexhibition' element={<AllExhibition />} />
                <Route path='/allexhibition/:id' element={<AllExhibitionDetailPage />} />
                <Route path='/history' element={<LearnHistory />} />
                <Route path='/giftshoppayout' element={<GiftShopPayout />} />
                <Route path='/donate' element={<Donate />} />
                <Route path='/attendant' element={<AttendanceLog />} />
                <Route path='/history/:id' element={<HistoryDetail />} />
                <Route path='/admin' element={<Admin />} />
                <Route path='/adminshop' element={<AdminShopPage  />} />
                <Route path='/AdminShopProductDetail/:id' element={<AdminShopProductDetail/>} />
                <Route path='/AdminAddProducttoGiftShop' element={<AdminAddProducttoGiftShop  />} /> 
                <Route path='/adminExhibition' element={<AdminExhibitionPage  />} /> 
                <Route path='/adminExhDetail/:id' element={<AdminExhDetailPage />} />
                <Route path='/addexhibition' element={<AddNewExhibition  />} /> 
                <Route path='/admingallery' element={<AdminGalleryPage  />} /> 
            </Routes>
        </AnimatePresence>
    )
}

export default NavRouter