import { useState, React } from 'react'
import Slider from 'react-slick'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

import '../Slide.css'
//import images
import Image2 from '../assets/exhibition/Frame 86.png'
import Image3 from '../assets/exhibition/Frame 86.png'
import Image1 from '../assets/exhibition/Frame 86.png'
import Image4 from '../assets/exhibition/Frame 86.png'



const images = [Image1, Image2, Image4, Image3]

const Slide = () => {

    const NextArrow = ({ onClick }) => {
        return (
            <div className='arrow next' onClick={onClick}>
                <FaArrowRight />
            </div>
        )
    }
    const PrevArrow = ({ onClick }) => {
        return (
            <div className='arrow prev' onClick={onClick}>
                <FaArrowLeft />
            </div>
        )
    }
    const [imageIndex, setImageIndex] = useState(0)

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next)
    }
    const mobileSettings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next)
    }
    return (
        <div>
            <div className='hidden md:block slide-img'>
                <Slider {...settings}>
                    {images.map((img, idx) => (
                        <div className={idx === imageIndex ? "slide activeSlide" : 'slide'}>
                            <div className='bg-[#FBEBD6] md:pt-10 md:p-4 lg:pt-14  lg:p-9  '>
                                <img className='flex items-center justify-center  h-[250px]' src={img} alt={'imges_slide'} />
                            </div>
                            <div className='relative bg-white md:mt-[-80px] lg:mt-[-31%] xl:mt-[-24%] md:mr-[15%] lg:mr-[35%]  md:p-2   xl:mr-[40%]'  >
                                <p className='text-[#818285] text-[80%]'>Sep 7, 2024</p>
                                <h1 className='text-[#2A564C] font-extrabold tracking-[5px]'>IZIZI</h1>
                                <p className='text-[#1A1C21] text-[80%]'>(The First Time) Art Exhibition</p>
                            </div>
                        </div>
                    ))}
                </Slider>
                
            </div>


            <div className='md:hidden mx-3 slide-img mt-10'>
                <Slider {...mobileSettings}>
                    {images.map((img, idx) => (
                        <div className={idx === imageIndex ? " activeSlide" : 'slide'}>
                            <div className='bg-[#FBEBD6]  p-16 pb-10 md:pb-6 md:bg-none'>
                                <img className='flex items-center justify-center ' src={img} alt={'imges_slide'} />
                            </div>
                            {/* <div className='relative bg-white mt-[-80px]  p-2 '  >
                                <p className='text-[#818285] text-[80%]'>Sep 1 - Sep 7, 2024</p>
                                <h1 className='text-[#2A564C] font-extrabold tracking-[5px]'>Modern Art</h1>
                                <p className='text-[#1A1C21]'>Lugsail halter crimp</p>
                            </div> */}
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Slide