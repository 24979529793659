import React from "react";
import { Link } from "react-router-dom";
import "../LoginModal.css";

const LoginAttendantModal = ({ visibility, onClose }) => {
  return (
    <div
      className="loginmodal"
      style={{ display: visibility ? "block" : "none" }}
    >
      <div className="login  w-[94%] lg:w-[35%]">
        <button
          className="text-[#1A1C21] text-[16px] font-semibold float-right"
          onClick={onClose}
        >
          X
        </button>
        <div className="flex items-center justify-center space-x-4 mt-10">
          <div className="bg-[#1A1C21] py-4 px-8 rounded text-[#F6F8F8] tracking-widest">
            <Link to={"/attendant"}>Attendant</Link>
          </div>
          <div className="border border-[#1A1C21] text-[#1A1C21] py-4 px-8 rounded tracking-widest">
            <Link to={"/login"}>Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAttendantModal;
