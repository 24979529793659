import React from 'react';

const Popup = ({ type, message, onClose }) => {
 const bgColor = type === 'success' ? 'bg-green-100' : 'bg-red-100';
 const borderColor = type === 'success' ? 'border-green-400' : 'border-red-400';
 const textColor = type === 'success' ? 'text-green-700' : 'text-red-700';
 const hoverColor =
  type === 'success' ? 'hover:text-green-700' : 'hover:text-red-700';

 return (
  <div
   className={`${bgColor} border ${borderColor} ${textColor} px-4 py-3 rounded relative pr-10 max-w-md`}
   role='alert'
  >
   <strong className='font-bold'>
    {type === 'success' ? 'Success!' : 'Error!'}
   </strong>
   <span className='block sm:inline ml-2'>{message}</span>
   <button
    className={`absolute top-0 right-0 mt-1 mr-1 ${textColor} ${hoverColor}`}
    onClick={onClose}
   >
    <span className='text-xl'>&times;</span>
   </button>
  </div>
 );
};

export default Popup;
