import React from 'react'
import { useParams } from 'react-router-dom'
import Data from '../data/gallery.json'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'



const GalleryImageDetail = () => {
    const { id } = useParams()
    //const {category} = useParams()

    const imageDetail = Data.find(items => {
        return items.id === parseInt(id)
    })
    if (!imageDetail) {
        return <section>Loading....</section>
    }
    // const similarImage = Data.map(items => {
    //     return items.category === "men's clothing"
        
    // })
    // console.log(similarImage)
    //  const [image] =similarImage

    return (
        <div>
            <MenuBar />
            <div className='lg:grid grid-cols-2 items-center justify-center mx-4 lg:mx-28 mt-10'  >
                <div className='md:flex-col flex justify-center'>
                    <img className='lg:w-[500px] h-[500px] '  src={imageDetail.image} alt={imageDetail.title} />
                </div>
                <div className='lg:w-[500px] pt-8 lg:pt-0 lg:h-[500px]  leading-10 items-center justify-center'>
                    <h1 className='text-3xl tracking-widest px-4 pb-4 font-bold'>{imageDetail.title}</h1>
                    <p className='px-4'>{imageDetail.description.substring(0, 300)}</p>
                </div>
            </div>
            <div className='bg-[#FFFAF5]  lg:mx-28 lg:my-20 mx-4 my-8 lg:p-10'>
                <h1 className='py-4 tracking-widest p-4 font-bold text-3xl '>Art Description</h1>
                <p className='leading-10 p-4'><span className='font-semibold'>description: </span>{imageDetail.description}</p>
            </div>
            <div>
           
         {/* <div className='grid grid-cols-4'>
         <img src={similarImage.image} alt={similarImage.title} />
         </div> */}
         {/* <h1>{similarImage.id}</h1> */}
            </div>
            <Footer />
        </div>
    )
}

export default GalleryImageDetail