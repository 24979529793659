import React, { useState } from "react";
import Nav from './Nav'
import Image1 from '../assets/admin/image 12.png'
import MiniCard from '../assets/admin/mini card.png'
import AddItem from '../assets/admin/add.png'
import PreviousExhibition from '../data/allExhibitions.json'
import UpcomingExhibition from '../data/upcomingexhibitions.json'
import { Link } from 'react-router-dom'

function AdminExhibitionPage() {
    const [active, setActive] = useState("PastExhibition");
    return (
        <div>
            <div className='lg:flex'>
                <div>
                    <Nav />
                </div>

                <div className='w-full'>

                    <div className=' mx-14 my-4 flex text-justify justify-between'>
                        <div><p className='font-medium'>Home</p></div>
                        <div><p className='font-medium'>Hello Admin</p></div>
                    </div>

                    <div className='flex justify-between bg-[#FBEBD6] mt-8'>
                        <div className='p-8'>
                            <h1 className='tracking-widest text-[21px] font-semibold text-[#818285]'>EXHIBITIONS</h1>
                            <p className='font-medium text-[12px] pt-2 text-[#818285]'>Details on all exhibitions</p>
                        </div>
                        <div className='mt-8 md:mt-0 mx-2'>
                            <img className='w-[300px] flex justify-center' src={Image1} alt='gallery 1' />
                        </div>
                    </div>



                    <div className='md:flex justify-center gap-4 mt-10 space-y-4 md:space-y-0 mx-2 md:mx-0'>
                        <div className='flex bg-[#F2F2F3] p-4 gap-2 rounded-md'>
                            <div>
                                <img src={MiniCard} alt='all products' />
                            </div>
                            <div>
                                <p className='text-[13px]'>All Exhibitions</p>
                                <p className='font-medium text-[18px]'>1</p>
                            </div>
                        </div>

                        <div className='flex bg-[#F2F2F3] gap-2 p-4 rounded-md'>
                            <div>
                                <img src={MiniCard} alt='Available products' />
                            </div>
                            <div>
                                <p className='text-[13px]'> Upcoming Exhibition</p>
                                <p className='font-medium text-[18px]'>1</p>
                            </div>
                        </div>

                        <div className='flex bg-[#F2F2F3] gap-2 p-4 rounded-md'>
                            <div>
                                <img src={MiniCard} alt='revenue generated' />
                            </div>
                            <div>
                                <p className='text-[13px]'>Revenue Generated</p>
                                <p className='font-medium text-[18px]'>0</p>
                            </div>
                        </div>


                        <Link to={'/addexhibition'} className='flex bg-[#FDDDB8] gap-2 p-4 rounded-md'>
                            <div>
                                <img src={AddItem} alt='add products' />
                            </div>
                            <div className='text-[13px] mt-2'>Add New</div>
                        </Link>
                    </div>


                    {/* click to display exhibitions */}
                    <div className="flex justify-center md:justify-start  bg-[#FDFDFD] mx-2  mt-10 md:mx-8 rounded-md">

                        <div>
                            <div
                                className={`cursor-pointer ${active === "PastExhibition" ? "text-[#2A564C] bg-[#F6F8F8] py-3 border-b-4 px-12 md:px-16 border-[#2A564C]" : "text-[#818285] py-3 px-12 md:px-16"
                                    }`}
                                onClick={() => setActive("PastExhibition")}
                            >

                                <p className=" text-[14px] md:text-[17px]">
                                    Past Exhibitions
                                </p>

                            </div>
                        </div>


                        <div>
                            <div
                                className={`cursor-pointer ${active === "upcomingexhibition" ? "text-[#2A564C] bg-[#F6F8F8] p-3 border-b-4 px-10 md:px-16 border-[#2A564C]" : "text-[#58595D] py-3 px-12 md:px-16"
                                    }`}
                                onClick={() => setActive("upcomingexhibition")}
                            >
                                <p className=" text-[14px]  md:text-[17px]">
                                    Upcoming Exhibition
                                </p>

                            </div>
                        </div>
                    </div>


                    {/* Upcoming and previous exhibition  dashboard */}
                    <div className="border mx-2 md:mx-8 my-8 rounded-md">

                        {/* Past Exhibition starts here */}
                        {active === "PastExhibition" && (
                            <div
                                className=" "
                                title="PastExhibition"
                            >

                                <div className=" md:flex justify-between mt-4 mx-2  md:mx-10 md:mt-8 mb-12 space-y-2 md:space-y-0">
                                    <div>
                                        <h1 className="text-[#2A564C] font-semibold tracking-widest ">EXHIBITIONS</h1>
                                    </div>

                                </div>
                                {/* {text} */}

                                <table className="flex  justify-center">
                                    <div className="w-full ">


                                        <div className=" flex justify-center p-4 md:w-full md:space-x-32 bg-[#F2F2F3] text-[#2A564C] space-x-8 text-[11px]  md:text-[16px]">
                                            <div className="">EXHIBITIONS</div>
                                            <div>DATE</div>
                                            <div>TICKET SALES</div>
                                            <div>REVENUE</div>
                                        </div>

                                        {PreviousExhibition.map((val) => (
                                            <Link to={`/adminExhDetail/${val.id}`}>
                                                <tr className=" flex justify-center border space-x-14 md:space-x-36 p-4" key={val.id}>
                                                    <td className="flex">

                                                        <img className='md:w-[200px] md:max-w-200px] w-[200px] max-w-[100px] h-[50px]  md:h-[100px] md:p-4' src={val.image} alt={val.title} />

                                                        <p className="hidden lg:block mt-10">{val.title.substring(0, 5)}</p>
                                                    </td>

                                                    <td className='pt-4 pb-2'>
                                                        <h5 className='text-left text-[#1A1C21] text-[14px]'>{val.date.substring(0, 8)}</h5>

                                                    </td>

                                                    <td>
                                                        <p>{val.revenue}</p>
                                                    </td>





                                                    <td>
                                                        <p>{val.revenue}</p>
                                                    </td>
                                                </tr>
                                            </Link>
                                        ))}

                                    </div>

                                </table>

                                {/* {images} */}

                            </div>
                        )}


                        {/* upcoming Exhibition starts here */}
                        {active === "upcomingexhibition" && (
                            <div
                                className=" "
                                title="upcomingexhibition"
                            >

                                <div className=" md:flex justify-between mt-4 mx-2  md:mx-10 md:mt-8 mb-12 space-y-2 md:space-y-0">
                                    <div>
                                        <h1 className="text-[#2A564C] font-semibold tracking-widest ">EXHIBITIONS</h1>
                                    </div>

                                </div>
                                {/* {text} */}

                                <table className="flex  justify-center">
                                    <div className="w-full ">


                                        <div className=" flex justify-center p-4 md:w-full md:space-x-32 bg-[#F2F2F3] text-[#2A564C] space-x-8 text-[11px]  md:text-[16px]">
                                            <div className="">EXHIBITIONS</div>
                                            <div>DATE</div>
                                            <div>TICKET SALES</div>
                                            <div>REVENUE</div>
                                        </div>
                                        {UpcomingExhibition.map((val) => (
                                            <Link to={'/addexhibition'}>
                                                <tr className=" flex justify-center border space-x-14 md:space-x-36 p-4" key={val.id}>
                                                    <td className="flex">

                                                        <img className='md:w-[200px] md:max-w-200px] w-[200px] max-w-[100px] h-[50px]  md:h-[100px] md:p-4' src={val.image} alt={val.title} />

                                                        <p className="hidden lg:block mt-10">{val.title.substring(0, 5)}</p>
                                                    </td>

                                                    <td className='pt-4 pb-2'>
                                                        <h5 className='text-left text-[#1A1C21] text-[14px]'>{val.date.substring(0, 8)}</h5>

                                                    </td>

                                                    <td>
                                                        <p>{val.revenue}</p>
                                                    </td>





                                                    <td>
                                                        <p>{val.revenue}</p>
                                                    </td>
                                                </tr>
                                            </Link>
                                        ))}

                                    </div>

                                </table>

                                {/* {images} */}

                            </div>
                        )}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminExhibitionPage