import React from 'react'
//import Icon2 from '../assets/bookticket/icon2.png'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import Visacard from '../assets/bookticket/logos_visa.png'
import Mastercard from '../assets/bookticket/logos_mastercard.png'
import PaypalLogo from '../assets/bookticket/paypal.png'

const GiftShopPayout = () => {
    return (
        <div>
          
            <div>
                <div className='bg-[#171D1A] pb-60 '>
                    <div className=' ml-[19%] pb-4 pt-20' >
                        <p className=' text-white text-[19px]'>  <Link className='' to={'/'}>Home </Link>  |  <Link className='' to={'/giftshop'}>Giftshop </Link> | <span className='text-[#F88603]' >Checkout</span></p>
                    </div>

                </div>


                <div className='lg:flex  items-center justify-center mt-[-16%] pb-20'>

                    <div className='bg-[#fff] items-center text-center justify-center  border border-[#171d1a] px-20' >
                        <div className='mt-9'>
                            <p>PAYMENT METHODS</p>
                        </div>
                        <div className='flex items-center justify-center shadow-lg rounded-md py-8 my-8 mx-[15%]  xl:mx-4 md:mx-[39%] lg:mx-[0%]'>
                            <img src={Visacard} alt='visa logo img' />
                        </div>
                        <div className='flex my-8 items-center justify-center  shadow-lg rounded-md py-8 mx-[15%] md:mx-[39%] xl:mx-4 lg:mx-[0%] '>
                            <img src={Mastercard} alt='visa logo img' />
                        </div>
                        <div className='flex my-8 items-center justify-center'>
                            <img src={PaypalLogo} alt='visa logo img' />
                        </div>

                    </div>


                    <div className='bg-[#171D1A] text-[#fff] py-[93px] px-8 lg:px-20 ' >


                        <form>
                            <label className='text-[12px]'>Card Number</label><br />
                            <input className=' border-b border-white  bg-[#171d1a] w-full' type='text' /> <br /><br />

                            <label className='text-[12px]'>Card Holder Name</label><br />
                            <input className='border-b border-white bg-[#171d1a] w-full ' type='text' />

                            <div className='flex space-x-10  justify-between mt-4 '>
                                <div>
                                    <label className='text-[12px]'>Expiration Date</label><br />
                                    <input className='border-b w-full border-white bg-[#171d1a] ' type='date' />
                                </div>
                                <div>
                                    <label className='text-[12px]'>CVV</label><br />
                                    <input className='border-b w-full border-white bg-[#171d1a] ' type='number' />
                                </div>
                            </div>
                            <div className='mt-8  bg-[#2A564C]' >
                                <div className='flex justify-between mb-2 '>
                                    
                                    <input className='w-full placeholder:text-[12px] p-2 placeholder:mr-4 bg-[#2A564C] placeholder:text-[#fff] ' type='number' placeholder='Amount' />
                                </div>
                                {/* <div className='flex justify-between'>
                                    <label className='text-[12px]   mx-2'>Total Amount</label>
                                    <input className='w-full placeholder:text-[12px] placeholder:mr-4 bg-[#2A564C] placeholder:text-[#fff] placeholder:float-right ml-8' type='text' placeholder='N5000 NGN' />
                                </div> */}
                            </div>
                            <div className='items-center justify-center flex mt-8'>
                                <button className='bg-[#2A564C] w-full px-24 py-4 rounded'>Confirm Payment</button>
                            </div>
                        </form>

                    </div>
                </div>
                <div className='bg-[#171D1A] p-8'></div>

              


                
            </div>
            <Footer />
        </div>
    )
}

export default GiftShopPayout