import React from 'react'
import allExhibitions from '../data/allExhibitions.json'
import { Link } from 'react-router-dom'
import Arrowright from '../assets/exhibition/Icon.png'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'

function AllExhibition() {
    return (
        <div>
            <MenuBar />
            <div className='text-center mt-10'>
                <div>
                    <h1 className='text-[#F88603] text-6xl  xl:text-[75px] lg:text-[75px] lg:font-extrabold font-bold xl:eading-[60px] xl:tracking-[12px] tracking-[3px]'>All Exhibitions</h1>
                </div>
                <div className='lg:mx-[270px] text-[19px] lg:text-[12px]  text-center mx-8 text-[#818285] py-10'>
                    <p className=' md:mx-32'>Visit us for any of our special themed exhibitions. See below for current and upcoming events
                    </p>
                </div>
                <hr className='mx-4 xl:block xl:mx-36 ' />
                {/* <div className='boder-4'></div> */}

            </div>
            

            {/* {fetch upcoming exhibition} */}
            <div className='lg:grid grid-cols-3   items-center lg:gap-4 justify-center my-12 mx-4 space-y-4  lg:space-y-4 md:mx-44 lg:mx-4  xl:mx-28' >
                {allExhibitions.map((va) => (
                    <div className=' bg-[#FEFBF8] p-2 rounded-md lg:max-w-[400px] h-[560px] ' key={va.id}>
                        <Link to={`/allexhibition/${va.id}`}>
                            <div className='flex justify-center'>
                                <img className='lg:w-[450px] h-[250px] w-full' src={va.image} alt={va.title} />
                            </div>
                            <div className=''>
                                <p className='text-[#818285] py-4 text-[13px]'>
                                    {va.date}
                                </p>
                                <h1 className='text-[#1A1C21] text-2xl font-bold tracking-widest pb-4'>
                                    {va.title}
                                </h1>
                                <p className='text-[15px] '>
                                    {va.description.substring(0, 200)}...
                                </p>
                            </div>

                            <Link to={`/allexhibition/${va.id}`} className='flex  pt-4'>Learn more <img src={Arrowright} alt='icon' />
                            </Link>
                        </Link>
                    </div>
                ))}

            </div>
            <Footer />
        </div>
    )
}

export default AllExhibition