import React from 'react'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import Arrow from '../assets/about/Icon.svg'
import Image1 from '../assets/about/image1.png'
import Image2 from '../assets/about/image2.png'
import Image3 from '../assets/about/image3.png'
import Image4 from '../assets/about/image4.png'
import Director from '../assets/about/Director.jpeg'

const About = () => {
  return (
    <div>
      <MenuBar />
      <div>
        <div className=' pt-24 pb-0'>
          <div className='text-center '>
            <div>
              <h1 className='text-[#1A1C21] text-5xl font-semibold  md:text-6xl  xl:text-[75px] lg:text-[75px] lg:font-extrabold md:font-bold xl:eading-[60px] xl:tracking-[12px] tracking-[3px]'>About our Museum</h1>
            </div>
            <div className='lg:mx-[270px] text-[19px] lg:text-[15px]  text-center mx-8 text-[#818285] py-10'>
              <p className=' md:mx-32'>Welcome to our museum, where history, art, and culture come to life. We inspire and educate visitors through diverse exhibits, engaging programs, and special events
              </p>
            </div>

            <div className='md:flex items-center mx-4 space-y-4 md:space-y-0 justify-center gap-4 py-8' >
              <div className='px-16 py-5 mr-24 md:mr-0 bg-[#2A564C] font-semibold'>
                <Link to={'/bookticket'} className=' text-[#FBFCFD]  text-[20px] rounded'>Book ticket</Link>
              </div>
              <div className='flex items-center justify-center border border-[#2A564C] px-16 py-5 text-[20px] space-x-2 font-semibold text-[#2A564C]'>
                <Link to={'/history'} className=''>Learn about history </Link>
                <img src={Arrow} alt='arrow' />
              </div>
            </div>
          </div>

          {/* {images} */}
          <div className='flex items-center gap-4 justify-center pt-20 mx-4 mb-[-100px] md:mx-8  md:mb-[-300px] '>
            <div>
              <img className='w-[400px]' src={Image1} alt='imgae1' />
            </div>
            <div>
            <img className='w-[400px]' src={Image2} alt='imgae2' />
            </div>
          </div>
        </div>

        <div className='bg-[#1A1C21] py-8 md:py-60 px-4'>

          {/* {story} */}
          <div className='md:flex  gap-10 justify-center pt-52 space-y-20 md:space-y-0'>
            {/* {text} */}
            <div className='text-[#F6F8F8] md:w-[530px] '>
              <p className='font-semibold'>OUR STORY</p>
              <h1 className='tracking-widest text-3xl font-extrabold py-4'>Founded 900 AD; Built in 2020</h1>
              <p>
              From being the living place of Nri in 900 AD, 
              the forefather or Enugwu-Ukwu to serving as the 
              gathering points of the sons of Nri for meetings,
               burials and market activities. Isi-Nri museum,
                built in 2020 by Igwe Ralph Obumneme Ekpeh, 
                Eze-Enugwu Ukwu na Igwe Umunri stands as a unifier 
                for all the sons of Nri and currently houses artifacts 
                and monuments that will help recreate the mystic 
                nature of Nri. 
              </p>
            </div>

            {/* {images} */}
            <div className=''>
              <img className='w-[410px]' src={Image3} alt='our story' />
            </div>
          </div>

          {/* {vision} */}
          <div className='md:flex justify-center mt-28 md:gap-10  lg:mx-36 pb-20 md:pb-0'>
            {/* {IMAGE} */}
            <div className='md:hidden pb-20 text-[#F6F8F8] md:w-[530px] '>
              <p className='font-semibold'>OUR VISION</p>
              <h1 className='tracking-widest text-4xl font-extrabold py-4'>Inspire.<br /> Connect. <br /> Include.</h1>
              <p>
                To inspire curiosity, foster learning, and connect people through the power of art, history, and culture. We aim to create an inclusive space where diverse stories are told, creativity is celebrated, and everyone feels welcome.
              </p>
            </div>
            <div>
              <img className='w-[510px] h-[510px]' src={Image4} alt='our vision' />
            </div>
            {/* {TEST} */}
            <div className='hidden md:block text-[#F6F8F8] md:w-[530px] '>
              <p className='font-semibold'>OUR VISION</p>
              <h1 className='tracking-widest text-4xl font-extrabold py-4'>People.<br /> History. <br /> Unity.</h1>
              <p>
              To remember the history of our people and 
              unite to build a stronger future where the
               identity of our children is not lost
              </p>
            </div>
          </div>
        </div>
         
         {/* {our director} */}
        <div className='hidden md:block py-32 mx-4'>
          <div className='flex justify-center gap-10'>
            <div className='w-[530px] text-[#1A1C21] mb-8'>
              <p className='font-semibold'>OUR DIRECTOR</p>
              <h1 className='text-[#1A1C21] tracking-widest font-extrabold text-4xl py-4'>About our Director</h1>
              <p>
              Driven by History; Dedicated to Humanity<br /><br />
              The Director, Kachi Aghasili is a USA trained 
              and practicing attorney who is driven by passion to
               tell the history of our people through art.
                She has been at the forefront of art and culture 
                and given her all to make sure all of our people
                 are connected by the first bond of brotherhood 
                 which we once shared.
              
              </p>
            </div>
            {/* { director image} */}
            <div className='mr-4'>
              <div>
                <img className='w-[430px]  h-[550px]' src={Director} alt='director_image' />
              </div>
              <div className='bg-[#C3D0CD] h-[550px] lg:mr-[-30px] ml-8 mt-[-520px]'></div>
            </div>
          </div>
        </div>


      {/* mobile view */}
        <div className='md:hidden py-32 mx-4'>
          <div className=''>
            <div className=' text-[#1A1C21] mb-8'>
              <p className='font-semibold'>OUR DIRECTOR</p>
              <h1 className='text-[#1A1C21] tracking-widest font-extrabold text-4xl py-4'>About our Director</h1>
              <p>
              Driven by History; Dedicated to Humanity<br /><br />
              The Director, Kachi Aghasili is a USA trained 
              and practicing attorney who is driven by passion to
               tell the history of our people through art.
                She has been at the forefront of art and culture 
                and given her all to make sure all of our people
                 are connected by the first bond of brotherhood 
                 which we once shared.
              
              </p>
            </div>
            {/* { director image} */}
            <div className=''>
              <div>
                <img className='w-full h-[550px]' src={Director} alt='director_image' />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About