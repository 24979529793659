import React from "react";
import '../shoppingcart.css'
import { Link } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";

function ShoppingCart({
	visibilty,
	products,
	onProductRemove,
	onClose,
	onQuantityChange,
}) {
	return (
		<div
			className="modal" style={{ display: visibilty ? "block" : "none", }}>
			<div className="shoppingCart">
				<div className="header">
					<h2>Shopping cart</h2>
					<button
						className="btn close-btn"
						onClick={onClose}>
						<AiFillCloseCircle
							size={30}
						/>
					</button>
				</div>
				<div className="cart-products">
					{products.length === 0 && (
						<span className="empty-text">
							Your basket is
							currently empty
						</span>
					)}
					{products.map((product) => (
						<div
							className="md:flex border-2 border-[#eee] bg-[#fff] w-full items-center py-8 px-4 justify-between"
							key={product.id}>
							<img
								src={
									product.image
								}
								alt={product.name}
							/>
							<div className="product-info ">
								<h3>
									{product.title.substring(0, 12)}
								</h3>
								<span className="product-price">
									${product.price *
										product.count}
									
								</span>
							</div>
							<select
								className="count border border-[#2A564C] mr-2"
								value={
									product.count
								}
								onChange={(
									event
								) => {
									onQuantityChange(
										product.id,
										event
											.target
											.value
									);
								}}>
								{[
									...Array(
										10
									).keys(),
								].map(
									(number) => {
										const num =
											number +
											1;
										return (
											<option
												value={
													num
												}
												key={
													num
												}>
												{
													num
												}
											</option>
										);
									}
								)}
							</select>
							<button
								className="btn remove-btn"
								onClick={() =>
									onProductRemove(
										product
									)
								}>
								<RiDeleteBin6Line
									size={20}
								/>
							</button>
						</div>
					))}
					{products.length > 0 && (
						<Link to={'/giftshoppayout'} className="btn checkout-btn">
							Proceed to checkout
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}

export default ShoppingCart;
