import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Icon from '../assets/giftshop/Icon.png'
import Car from '../assets/giftshop/image 23.png'
import Card from '../assets/giftshop/credit-card.png'
import Phone from '../assets/giftshop/phone-call.png'
import { Link } from 'react-router-dom'
import Product from './giftshop/Product.json'
import MenuBar from '../components/MenuBar'
import Footer from '../components/Footer'
import ShoppingCart from './ShoppingCart'
import { GiShoppingBag } from "react-icons/gi";




const Detail = () => {
  const [cartsVisibilty, setCartVisible] = useState(false);

  const [productsInCart, setProducts] = useState(
    JSON.parse(
      localStorage.getItem(
        "isinrimuseumwebapp"
      )
    ) || []
  );
  const onQuantityChange = (productId, count) => {
    setProducts((oldState) => {
      const productsIndex =
        oldState.findIndex(
          (item) =>
            item.id === productId
        );
      if (productsIndex !== -1) {
        oldState[productsIndex].count =
          count;
      }
      return [...oldState];
    });
  };

  const onProductRemove = (product) => {
    setProducts((oldState) => {
      const productsIndex =
        oldState.findIndex(
          (item) =>
            item.id === product.id
        );
      if (productsIndex !== -1) {
        oldState.splice(productsIndex, 1);
      }
      return [...oldState];
    });
  };

  const addProductToCart = (product) => {
    const newProduct = {
      ...product,
      count: 1,
    };
    setProducts([
      ...productsInCart,
      newProduct,
    ]);
  };

  // {25 August}
  const { id } = useParams()
  const product = Product.find(item => {
    return item.id === parseInt(id)
  })

  if (!product) {
    return <section>Loading....</section>
  }

  const { title, image, price, description } = product
  return (
    <>
      <MenuBar />
      <div className='items-center justify-center mx-auto'>
        <div className=' lg:mx-48 pt-10 mx-4 '>

          {/* {26 August} */}
          <ShoppingCart
            visibilty={cartsVisibilty}
            products={productsInCart}
            onClose={() =>
              setCartVisible(false)
            }
            onQuantityChange={
              onQuantityChange
            }
            onProductRemove={onProductRemove}
          />

          <button
            className='flex float-right pt-6 mr-10'

            onClick={() =>
              setCartVisible(true)
            }> <p className='text-[13px] text-[#2A564C] mr-2 mt-1 '>View cart</p>
            <GiShoppingBag size={24} />
            {productsInCart.length >
              0 && (
                <span className=' bg-[#2A564C] py-1 px-2 text-[#fdfdfd] text-[10px] rounded-full'>
                  {
                    productsInCart.length
                  }
                </span>
              )}
          </button>
          <p className='text-[12px]'><Link to={'/'}>Home</Link> / <Link to={'/giftshop'}>Shop</Link> / <span className='font-bold'>{title.substring(0, 12)}</span></p>
        </div>
        <div className='lg:grid grid-cols-2  justify-center lg:mx-32 mt-10' >
          <div className='mx-8'>
            <div className='lg:flex items-center justify-center '>
              <img className='w-[350px] h-[350px]' src={image} alt={title} />
            </div>
            <div className='flex  lg:items-center lg:justify-center mt-4 space-x-4'>
              <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
              <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
              <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
              <div> <img className='w-[70px] h-[70px]' src={image} alt={title} /></div>
            </div>
          </div>

          <div className='pt-8 lg:pt-0 max-w-md mx-10'>
            <h1 className='text-[#2A564C] font-extrabold text-2xl tracking-wider mb-8'>{title}</h1>
            <p className='text-[#818285] text-[12px]'>{description}</p>
            <p className='py-4 text-[#2A564C] font-extrabold text-[18px]'>${price}</p>
            <div className='mt-8 flex'>
              <div>
                <Link
                  onClick={() =>
                    addProductToCart(
                      product
                    )
                  }
                  className='bg-[#2A564C] text-[#FDFDFD] px-10 py-4 rounded '>Add to cart</Link>
              </div>
              <div className='mx-4 border p-1 rounded mt-[-4px]'>
                <img src={Icon} alt='icon' />
              </div>
            </div>
            <hr className='mt-10' />
            <div className='mt-6'>

              <div className='flex'>
                <img className='' src={Car} alt='car' /> <p className='text-[11px] px-2'> Free Shipping</p>
              </div>

              <div className='flex my-2'>
                <img className='' src={Card} alt='car' /> <p className='text-[11px] px-4'> Secure Payment</p>
              </div>

              <div className='flex my-2'>
                <img className='' src={Phone} alt='car' /> <p className='text-[11px] px-4'> 24 / 7 Help Center</p>
              </div>

            </div>
            <div>

            </div>
          </div>
        </div>
        <hr className='mt-7' />
      </div>
      <Footer />
    </>
  )
}

export default Detail